import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom';
import AddApplicationContext from '../../../../contexts/addApplicationContext.js';

import succesLogo from "../../../../img/success.svg"

const FormFinish = () => {

    const addApplicationContext = useContext(AddApplicationContext);
    const updateContext = addApplicationContext.addApplicationDetails;
   
    const prev = () => {
        updateContext.setStep(updateContext.step - 1)
    };

    const finish = () => {
        console.log(updateContext);
    }
    return (
              <div className='form widzard-items'>          

                    <div className='head'>
                        <h4 className='title is-size-5-tablet is-size-4-desktop is-size-5-mobile'>Intégration de Emobpay pour {updateContext.platform}</h4>
                    </div>
                   
                    <div className='succesBody'>
                        <h5>Configurez Emobpay pour votre application  : {updateContext.platform}</h5>
                       
                        <ol>
                            <li>Télécharger l'extention Emobpay pour {updateContext.platform} <NavLink to='#'>ici</NavLink></li>
                            <li>Installer l'extention  sur votre application</li>
                            <li>Configurez l'extention </li>
                        </ol>
                        <NavLink to="" >Voir la démo utilisation de Emobpay sur {updateContext.platform}</NavLink>

                    </div>
                    <div className='extensionLink mt-6'>
                     <NavLink className='' to='#'>Télécharger l'extention Emobpay pour {updateContext.platform}</NavLink>

                    </div>

                    <div className='widzard-footer'>
                      <div className='widzard-btn'>
                 
            </div>   
        </div>   
             </div>
        
    );
};

export default FormFinish;
