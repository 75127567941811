import React, { useContext, useEffect, useState ,useRef} from 'react'
import '../Account.css';
import '../../../../theme/Table.css'
import Header from '../../../Layouts/headerDashbord';
import Footer from '../../../Layouts/Footer';
import Auth from '../../../../contexts/Auth';
import {getUser} from '../../../../services/user.service';
import {deleteAppServices, getAllAppServices} from '../../../../services/userApplication.service'
import {logOut} from '../../../../services/Auth';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import copyIcon from '../../../../img/copy.png';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Edit from './EditAppConfig'
import {errornotif, infoNotif, successnotif} from  '../../../../utils/notification'
import UserContext from '../../../../contexts/UserContext';
import SideMenu from '../SideMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPenToSquare,faTrash}  from '@fortawesome/free-solid-svg-icons';
import dateFormat, { masks } from "dateformat";



const AllSolution = () =>{



  const {isAuthenticated} =  useContext(Auth)
  const {currentUser,setCurrentUser} = useContext(UserContext)
  const [allApp ,setAllapp]= useState([])
  const [copied, setCopied] = useState(false);
  const [exchangeKey, setExchangeKey] = useState('emob_tt96rhO4QUAhSRqEkDO801Jsgj7HH');
  //const [q, setQ] = useState("");
 // const textAreaRef = useRef(null);
  const history = useHistory()
  //const [searchColumns, setSearchColumns] = useState([
   // 'id',
   // 'appName',
  //]);

  const handleDelateApp = async(id)=>{
   let result =window.confirm("Êtes-vous sûr de vouloir supprimer? ?")
     if(result){
       try {
            const response = await deleteAppServices(id)

            if(response.status===200) {
              window.location.reload();
              successnotif(response.data.message)
            }    
       } catch (error) {
        errornotif(error.response.data.message)
       }
        
     }

  }
 
  useEffect(() => {
    getUser().then(
      (response) => {
        setCurrentUser(response.data);
      },
      (error) => {
        console.log("Private page", error.response);
        // Invalid token
        if (error.response && error.response.status === 403) {
          logOut();
          history.push("/login")
          window.location.reload();
        }
      }
    );

    getAllAppServices().then((response)=>{
        setAllapp(response.data)
    })


  },[allApp]);





  return (
    <section className="home_contents">
      <Header/>
          <div class="columns is-variable is-0 mt-4 "> 
               <SideMenu currentPage='my-applications'/>
            <div class="column is-10-desktop  is-12-tablet  is-12-mobile ">
                <div class="p-1">
                  <div class="columns is-variable is-desktop   is-flex-direction-column	is-align-items-center	  has-shadow">
                    
                    <div class="column is-12 is-flex  is-flex-wrap-wrap  is-justify-content-space-around	">
                          <h4 class="title  is-4">
                            Mes applications
                          </h4>
                        <div>
                          <NavLink to='/add-application' className='button is-primary is-larger is-responsive' > Ajouter une nouvelle application</NavLink>
                        </div>    

                    </div>
               
                      <div class="table-container  is-11-desktop column mt-3 ">
                                <table id="table" class=" table table-bordered" >
                                <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th>Nom de l'application</th>
                                      <th>Url de l'application </th>
                                      <th>Plateforme</th>
                                      <th>Numéro de transaction</th>
                                      <th>Token</th>
                                      <th>Status</th>
                                      <th>Date d'ajout</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                
                                {(allApp.length > 0 )? 

                                 
                                  <tbody>
                                          {allApp.map((app,index) => (
                                            <tr key={app.id} className="odd">
                                                <td data-label="Id">{index}</td>
                                                <td data-label="Nom de l'application">{app.appName}</td>
                                                <td data-label="Url de l'application"><NavLink target='_blank' to={app.appUrl}>{app.appUrl}</NavLink></td>
                                                <td data-label="Plateforme">{app.platform}</td>
                                                <td data-label="Numéro de transaction">{app.transactionNumber}</td>
                                                <td  data-label="Token généré" >
                                                  <span className='exchangeKey-container'>
                                                    <textarea className='exchangeKey' rows="3" value={app.exchangeKey} disabled onChange={(e)=>{setExchangeKey(e.target.value)}}/> 
                                                    <CopyToClipboard text={app.exchangeKey} onCopy={()=>{ infoNotif('Copié !')}}>
                                                      <span className='exchangeKeyCopy'><img src={copyIcon}  alt="copier"/></span>
                                                    </CopyToClipboard> 
                                                  </span>
                                                  </td>
                                                  <td data-label="Statut"> {app.status ? <span className='has-text-success'>Actif</span> :<span className='has-text-danger'>Non actif</span> }</td>

                                                <td data-label="Date de création">{dateFormat(app.createdAt, "dd/mm/yyyy : H:MM")}</td>
                                                <td>
                                                  <NavLink to={`editAppConfig/${app.id}`} className="btn"><span class="icon-text has-text-dark">  <span class="icon"> <FontAwesomeIcon icon={ faPenToSquare}/></span></span> </NavLink>
                                                  
                                                  <NavLink  to="#" onClick={()=>{handleDelateApp(app.id)}} ><span class="icon-text has-text-dark">  <span class="icon"> <FontAwesomeIcon icon={faTrash}/></span></span> </NavLink>
                                                  
                                                </td>
                                            </tr>


                                      ))
                                          }
                                  
                                  </tbody>
                                  : <tr>Pas d'application</tr>}
                              </table>   
                            
                            
                          

                    </div>

                  </div>

              
            
                </div>
            </div>
          </div>
      <Footer />
    </section>
  )
}

export default AllSolution;