import React from 'react'
import { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck,faInfoCircle}  from '@fortawesome/free-solid-svg-icons';
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";

 const ActivationEmail =() =>{

    const {token}= useParams()
    const [err,setErr] = useState('')
    const [success,setSuccess] = useState('')

    useEffect(()=>{
        if(token){
            const data = {
                token:token
            }
            const ActivationEmail = async()=>{
                try {
                    let url = `${process.env.REACT_APP_SERVER_URL}/api/user/verify/`
                    const response  = await axios.post(url,data)

                    response.data.message &&   setSuccess(response.data.message)

                } catch (error) {
                   error.response.data.message && setErr(error.response.data.message)
                }
            }
            ActivationEmail()
        }
     
    },[token])


  return (            
          <section className="home_contents">
             
          <Header/>
                { success ? 
                    <div class="container">
                    <div class="colomuns is-flex is-justify-content-center  is-align-content-center mt-6" style={{ marginBottom: "2rem" }}>
                        <div class="column   is-11-mobile is-6-tablet is-6-desktop card">   
                                <div className="card-content">
                                    <div className="icon-container">
                                        <span class="icon-text has-text-primary">
                                        <span class="icon ">
                                            <FontAwesomeIcon icon={faCircleCheck}  />
                                            </span>
                                        <span>Success</span>
                                        </span> 
                                        <p className="description">{success}</p>
                                        <div className="card-footer">
                                        <a href="/login"> Aller à la page de connexion</a>
                                    </div>
                                    </div>
                                </div>

                        </div>
                    </div>
                  </div>

                :''}
                  { err ? 
                   <div class="container">
                   <div class="colomuns is-flex is-justify-content-center  is-align-content-center mt-6" style={{ marginBottom: "2rem" }}>
                       <div class="column   is-11-mobile is-6-tablet is-6-desktop card">   
                               <div className="card-content">
                                   <div className="icon-container">
                                       <span class="icon-text has-text-danger">
                                       <span class="icon ">
                                           <FontAwesomeIcon icon={faInfoCircle}  />
                                           </span>
                                       <span>Success</span>
                                       </span> 
                                       <p className="description mb-6">{err}</p>
                                       <div className="card-footer">
                                       <a href="/login"> Aller à la page de connexion</a>
                                   </div>
                                   </div>
                               </div>

                       </div>
                   </div>
                 </div>
                   
                : ''}
                
                <Footer/>
          </section>  

  )
}
export default ActivationEmail