import React, { Component } from "react";
import './mentions_legales.css';
import Header from '../../Layouts/Header';
import Footer from "../../Layouts/Footer";

class LegalMention extends Component {
    render() {
        return (
            <section className="mentions_legales_container">
                <Header />
                <main class="columns" style={{ marginBottom: "10rem" }}>
                    <div className="colomun is-12">
                    <h1 class="mentions_legales">Mentions Légales</h1>
                                <div class="container1 ">
                                    <h4 class="panel-title">
                                        <a href="/">1. Agréments</a>
                                    </h4>
                                    <p>
                                        Paiement par Mobile Money dispose d’: <br />
                                        – un agrément d’agrégateur de paiement lié à la société gérant le service MTN Mobile Money.
                                    </p>
                                </div>

                                <div class="container2 ">

                                    <h4 class="panel-title">
                                        <a href="/">2. Déclaration à l’APDP</a>
                                    </h4>

                                    <p>

                                        Conformément aux articles 405 et suivants de la loi
                                        n° 2017-20 portant code du numérique en République du Bénin
                                        (Livre 5ième relatif à la protection des données personnelles
                                        et de la vie privée),
                                        le site a fait l’objet d’une déclaration auprès de l’Autorité
                                        de Protection des Données à caractère Personnel sous le numéro
                                        RD n°025-2018/APDP/Pt/SG/DAJC/SA du 05 septembre 2018.
                                        Conformément aux dispositions de la loi n° 2017-20 portant code
                                        du numérique en République du Bénin, tout utilisateur dispose
                                        d’un droit d’accès (art. 437), d’interrogation (art. 439),
                                        de rectification (art. 441), de suppression (art. 441)
                                        et d’opposition (art. 440) aux données personnelles le concernant,
                                        en effectuant sa demande écrite et signée, accompagnée d’une
                                        copie du titre d’identité avec signature du titulaire de la
                                        pièce, en précisant l’adresse à laquelle la réponse doit être
                                        envoyée. Aucune information personnelle de l’utilisateur du
                                        site https://interface-ui-module-momo-floraaviss.vercel.app/
                                        n’est publiée à l’insu de l’utilisateur, échangée, transférée,
                                        cédée ou vendue sur un support quelconque à des tiers.
                                    </p>
                                </div>

                                <div class="container3 ">

                                    <h4 class="panel-title">
                                        <a href="/">3. Droit d’accès</a>
                                    </h4>
                                    <p>
                                        En application de cette loi, les internautes disposent d’un
                                        droit d’accès, de rectification, de modification et de
                                        suppression relativement aux données qui les concernent
                                        personnellement. Ce droit peut être exercé par voie postale
                                        auprès de RINTIO Company ou par voie électronique à l’adresse
                                        email suivante : contact@rintio.com. Les informations
                                        personnelles collectées ne sont en aucun cas confiées à des tiers.
                                    </p>
                                </div>

                                <div class="container4 ">

                                    <h4 class="panel-title">
                                        <a href="/">4. Droit d’introduire une réclamation</a>
                                    </h4>
                                    <p>
                                        L’utilisateur a le droit d’introduire auprès de l’autorité de
                                        protection des données à caractère personnel (APDP) une
                                        réclamation si elle considère que le traitement de données
                                        le concernant constitue une violation à la loi n°2017-20 portant
                                        Code du numérique en République du Bénin.
                                    </p>

                                </div>

                                <div class="container5 ">

                                    <h4 class="panel-title">
                                        <a href="/">5. Confidentialité </a>
                                    </h4>
                                    <p>
                                        Vos données personnelles sont confidentielles et ne seront
                                        en aucun cas communiquées à des tiers. A cet effet,
                                        et conformément à la loi n°2017-20 portant Code du numérique
                                        en République du Bénin, Paiement par Mobile Money s’engage à
                                        ne pas les divulguer.
                                    </p>

                                </div>

                                <div class="container6 ">

                                    <h4 class="panel-title">
                                        <a href="/">6. Propriété intellectuelle</a>
                                    </h4>
                                    <p>
                                        Tout le contenu du présent site,
                                        incluant, de façon non limitative,
                                        les graphismes, images, textes, vidéos,
                                        animations, sons, logos, gifs et icônes
                                        ainsi que leur mise en forme sont la propriété
                                        exclusive de Paiement par Mobile Money à l’exception
                                        des marques, logos ou contenus appartenant à d’autres
                                        sociétés partenaires ou auteurs. Toute reproduction,
                                        distribution, modification, adaptation, retransmission
                                        ou publication, même partielle, de ces différents éléments
                                        est strictement interdite sans l’accord exprès par écrit
                                        de RINTIO Company.
                                    </p>

                                </div>

                                <div class="container7 ">

                                    <h4 class="panel-title">
                                        <a href="/">7. Editeur de Services et audit de qualité et de conformit</a>
                                    </h4>
                                    <p>
                                        Les services de Paiement par Mobile Money sont édités par
                                        RINTIO Company, entreprise béninoise immatriculée auprès du
                                        Registre du Commerce et du Crédit Mobilier sous le Numéro
                                        RB/COT/17B19979 dont le siège est situé à
                                        Zogbo,Rue 2650,Cotonou(Bénin). RINTIO Company est éditeur
                                        de la web-application
                                        https://interface-ui-module-momo-floraaviss.vercel.app/
                                        qui permet aux particuliers et entreprises de pouvoir effectuer
                                        des paiements en ligne.
                                    </p>

                                    <br />

                                </div>
                    </div>
                   

                </main>
                <Footer />
            </section>
        );
    }
}

export default LegalMention;