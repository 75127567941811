import React, { useContext } from 'react';
import { set, useForm } from "react-hook-form";

import AddApplicationContext from '../../../../contexts/addApplicationContext.js';

const FormOne = (data) => {

    const { register,onChange,handleSubmit,trigger,reset,watch,formState: {errors,isDirty, isSubmitting ,isSubmitSuccessful,isValid},} = useForm();
    const addApplicationContext = useContext(AddApplicationContext);
    const updateContext = addApplicationContext.addApplicationDetails;

    const next = () => {
     updateContext.setStep(updateContext.step + 1)


    };

    const handleChangeAppName = (e)=> {
        console.log(e.target.value)
        updateContext.setAppname(e.target.value)
    }
    const handleChangeAppUrl = (e)=> {
        console.log(e.target.value)

        updateContext.setAppUrl(e.target.value)
    }
    const handleChangeAppPlatform = (e)=> {
        console.log(e.target.value)

        updateContext.setPlatForm(e.target.value)
    }
    const onSubmit = (data)=>{
        if(isValid){
            next();
        }
             
      }
   


    return (
       
                 <div className='form widzard-items'>          

                        <div className='head'>
                            <h4 className='title is-size-5-tablet is-size-4-desktop is-size-5-mobile'>Informations sur l'application</h4>
                        </div>
                        <form  onSubmit={handleSubmit(onSubmit)}>
                        <div className="input_field">
                                <label>Nom de l'application :<span className="isrequired">(*)</span></label>
                                <div className="control">  

                                     <input type='text' class="input"
                                     
                                        className={`input  ${errors.appName && 'error'}`}
                                        {...register("appName", {
                                            required: ' Le nom de l\'application est réquis',
                                        })}
                                        onKeyUp={(e)=>{
                                            trigger("appName");
                                            handleChangeAppName(e)

                                        }}
                                      
                                            
                                        />
                               
                                         {errors.appName && <small className="error">{errors.appName.message}</small> }
                                </div>
                        </div>
                        
                       <div className="input_field">
                            <label>Plateforme (CMS): <span className="isrequired">(*)</span></label>
                             <div className="control">  
                                <select 
                                    className={`input  ${errors.platform && 'error'}`}
                                     {...register("platform",{
                                        required: 'Ce champ  est réquis', 
                                           })}
                                            onKeyUp={(e)=>{
                                                trigger("platform");
                                            }}
                                           onChange={(e)=>{
                                            handleChangeAppPlatform(e)

                                           }}
                                      
                                         
                                       >
                                       <option value="">Sélectionner...</option>
                                        <option value="Prestashop">Prestashop</option>
                                        <option value="Wordpress (woocommerce)">Wordpress (woocommerce)</option>
                                       <option value="Shopify">Shopify</option>
                                                    
                                </select>
                                {errors.platform && <small className="error">{errors.platform.message}</small> }
                             </div>
                        </div>
                        <div className="input_field">
                                <label>Url de l'application : <span className="isrequired">(*)</span></label>
                            <div className="control">  
                                 <input type='text' class="input"
                                    className={`input  ${errors.appUrl && 'error'}`}
                                    placeholder="http://monapp.com"
                                    {...register("appUrl", {
                                        required: 'L\'url de l\'application est réquis',
                                        pattern : {
                                            value :/((http|https)\:\/\/||www)[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/,
                                            message :'Le  lien est invalid'
                                        } 
                                    })}
                                    onKeyUp={(e)=>{
                                        trigger("appUrl");
                                        handleChangeAppUrl(e)

                                    }}
                                
                                   
                                />
                                
                                {errors.appUrl && <small className="error">{errors.appUrl.message}</small> }
                          </div>
                             
                       </div>
                           <div className='widzard-footer'>
                           <div className='widzard-btn'>
  
                                    <div className='next'>
                                        <button type='submit'  className="button is-primary  next">Suivant</button>
                                    </div>
                            </div>   
                        </div>      
                </form>                   
        {/*  <h4>Informations sur l'application</h4>
            <div className='form'>
                     <div className="input_field">
                        <label>Nom de l'application :<span className="isrequired">(*)</span></label>
                             <div className="control">  
                                <input type='text' class="input"
                                    className={`appName  ${errors.email && 'error'}`}
                                    {...register("appName", {
                                        required: 'Le nom de l\'application  est réquis',
                                      
                                         })}
                                        onKeyUp={()=>{
                                             trigger("appName");
                                         }}
                                     />
                                        
                                     {errors.email && <small className="error">{errors.email.message}</small> }
                               
                                     </div>


                     </div>
            </div>
          */}
                 </div>
    );
};

export default FormOne;