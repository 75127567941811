import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleCheck}  from '@fortawesome/free-solid-svg-icons';



const  RegisterSuccessPage = ()=> {
  return (
    <section className="home_contents">
      <div class="container">
                <div class="colomuns is-flex is-justify-content-center  is-align-content-center mt-6" style={{ marginBottom: "2rem" }}>
                    
                    <div class="column   is-11-mobile is-6-tablet is-6-desktop card">
                        
                                <h3 className="has-text-dark	 is-size-4 is-uppercase	has-text-centered has-text-weight-bold 	"> Votre compte a été créé avec succès.</h3>
                    
                            <div className="card-content">
                            <div className="icon-container">
                              
                                <span class="icon-text has-text-primary">
                                  <span class="icon ">
                                     <FontAwesomeIcon icon={faCircleCheck}  />
                                    </span>
                                  <span>Success</span>
                                </span> 
                                <p className="description">Un e-mail vient de vous être envoyé.<br/>Pour finaliser votre inscription , rendez-vous dans votre boite e-mail pour confirmer votre e-mail.</p>
                                <div className="card-footer">
                                <a href="/login"> Aller à la page de connexion</a>
                               </div>
                            </div>
                            </div>

                    </div>
                </div>
      </div>
    </section>
  )
}
export default RegisterSuccessPage;
