import React from "react";
import { useRef,useState } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import { useForm } from "react-hook-form";
import RegisterSuccessPage from "./registrationSuccess"
import {successnotif,errornotif} from  '../../../utils/notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleNotch}  from '@fortawesome/free-solid-svg-icons';
import { registerServices } from "../../../services/Auth";
import { NavLink } from "react-router-dom";



    

const Register= ()=>{

    const { register,handleSubmit,trigger,reset,watch,formState: {errors,isDirty, isSubmitting ,isSubmitSuccessful,isValid},} = useForm();
    const [loading,setLoading]= useState(isSubmitting)
    const [registerSuccessfull,setRegisterSuccessfull]= useState(false)
    const [flahSuccess,setFlashSuccess]= useState('')
    const [flahError,setFlashError]= useState('')

    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = async(data)=>{  
       setLoading(true)
         try{       
                //incrire l'utilisateur  register de auth service
                const response = await registerServices(data)
                reset()
               successnotif(response.data.message)
                if(response.status===201){
                    setRegisterSuccessfull(true);
                    setLoading(false)
                }  
           }catch (error) {
            setLoading(false)
             errornotif(error.response.data.message)
             
        }  
          

    }


    return(
   
        <section className="home_contents">
        <Header/>
        {!registerSuccessfull ? 
        <div class="container">
        <div class="colomuns is-flex is-justify-content-center mt-1	 is-align-content-center mt-6" style={{ marginBottom: "10rem" }}>
          
          <div class="column   is-12-mobile is-12-tablet is-5-desktop card">
              
                      <h3 className="has-text-dark	 is-size-4 is-uppercase	has-text-centered has-text-weight-bold 	">Inscription</h3>
             
                  <div className="card-content">
                  <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form">
                                        <div className="input_field">
                                            <label>Nom complet : <span className="isrequired">(*)</span></label>
                                            <div className="control">  
                                                <input 
                                                    type='text' 
                                                    className={`input  ${errors.name && 'error'}`}
                                                    {...register("name",{
                                                        required: 'Le nom est réquis', 
                                                        
                                                    })}
                                                    onKeyUp={()=>{
                                                        trigger("name");
                                                    }}
                                                
                                                /> 
                                                {errors.name && <small className="error">{errors.name.message}</small> }
                                            </div>
                                        </div>
                                        <div className="input_field">
                                            <label>Email :<span className="isrequired">(*)</span></label>
                                            <div className="control">  
                                                <input 
                                                    type='email' 
                                                    className={`input  ${errors.email && 'error'}`}
                                                    {...register("email", {
                                                        required: 'L\'email est réquis',
                                                        pattern : {
                                                            value :/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                            message :'Votre email est invalid'
                                                        } 

                                                    })}
                                                    onKeyUp={()=>{
                                                        trigger("email");
                                                    }}
                                                
                                                />
                                                {errors.email && <small className="error">{errors.email.message}</small> }

                                            </div>  
                                        </div>
                                        <div className="input_field">
                                            <label>Téléphone : <span className="isrequired">(*)</span></label>
                                            <div className="control">  
                                                <input 
                                                    type='tel' 
                                                    className={`input  ${errors.phone && 'error'}`}
                                                    {...register("phone", {
                                                        required: 'Le numéro de téléphone est réquis',
                                                        pattern : {
                                                            value :/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
                                                            message :'Numéro de téléphone invalide'
                                                        }  
                                                    })}
                                                    onKeyUp={()=>{
                                                        trigger("phone");
                                                    }}
                                                />
                                                {errors.phone && <small className="error">{errors.phone.message}</small> }
                                            </div>
                                        </div>
                                        <div className="input_field">
                                            <label>Mot de passe : <span className="isrequired">(*)</span></label>
                                            <div className="control">  
                                                <input 
                                                    type='password'
                                                    className={`input  ${errors.password && 'error'}`}
                                                    {...register("password", {
                                                        required: 'Le mot de passe est réquis',
                                                        pattern : {
                                                            value :/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                                                            message :"- Au moins 8 caractères ; doit contenir au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre ;- Peut contenir des caractères spéciaux"
                                                        } 
                                                    })}
                                                    onKeyUp={()=>{
                                                        trigger("phone");
                                                    }}
                                                    />
                                                    {errors.password && <small className="error">{errors.password.message}</small> }
                                            </div>
                                        </div>
                                        <div className="input_field">
                                            
                                            <label>Confirmer le mot de passe : <span className="isrequired">(*)</span></label>
                                            <div className="control">  
                                                <input 
                                                    type='password'
                                                    className={`input  ${errors.confPassword && 'error'}`} 
                                                    {...register("confPassword",{
                                                        required:'Le champs est réquis',
                                                        validate: value =>
                                                        value === password.current || "The passwords do not match"
                                                   
                                                    })}
                                                    
                                                />
                                                {errors.confPassword && <small className="error">{errors.confPassword.message}</small> }
                                            </div>
                                        </div>
                                    
                                        <div className="footer">
                                            <label > 
                                                <input
                                                    type='checkbox'
                                                    {...register("isChecked", {
                                                        required: 'Le champ est réquis'
                                                    })}
                                                    onKeyUp={()=>{
                                                        trigger("isChecked");
                                                    }}
                                                /> 
                                                
                                                &nbsp;&nbsp;j’acccepte les  <NavLink to="/mentions_legales" className="link"> conditions d’utilisation  et la politique de confidentialité</NavLink>  de Emobpay

                                            </label>
                                            {errors.isChecked && <small className="error">{errors.isChecked.message}</small> }

                                            
                                        </div>
                                        <div className="inputfield">
                                            <button type='submit' className={`button  is-fullwidth is-success  ${!isValid? 'griser' : null} ${loading? 'is-loading' : ''}`} >  S'enrégistrer </button>
                                        </div>
                                    </div>
                                </form>
                  </div>
              

                   
                   <div className="card-footer">
                     <p> Déjà inscrit sur Emobpay ? <a href="/login"  className="link"> Connectez-vous</a></p>
                  </div>
            
          </div>
        </div>
        </div>
             
        : <RegisterSuccessPage/>}

          <Footer/>
       
      </section>
    
    );
}

export default Register;