import React, { useContext, useEffect, useState ,useRef} from 'react'
import './Account.css';
import '../../../theme/Table.css'
import Header from '../../Layouts/headerDashbord';
import Footer from '../../Layouts/Footer';
import Auth from '../../../contexts/Auth';
import {getUser} from '../../../services/user.service';
import {deleteAppServices, getAllAppServices} from '../../../services/userApplication.service'
import {logOut} from '../../../services/Auth';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import copyIcon from '../../../img/copy.png';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {errornotif, infoNotif, successnotif} from  '../../../utils/notification'
import UserContext from '../../../contexts/UserContext';
import SideMenu from './SideMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPenToSquare,faTrash}  from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { deleteOneTransactionServices, getTransactionByAppServices } from '../../../services/userTransaction.services';
import dateFormat, { masks } from "dateformat";


const TransactionHistoryApp = () =>{
    
    const {isAuthenticated} =  useContext(Auth)
    const {currentUser,setCurrentUser} = useContext(UserContext)
    const [appHistory,setAppHistory]= useState([])
    const history = useHistory()

    const handleDelateTransaction = async(id)=>{
        let result =window.confirm("Êtes-vous sûr de vouloir supprimer?")
        if(result){
        try {
                const response = await deleteOneTransactionServices(id)
                if(response.status===200) {
                    successnotif(response.data.message)

                    window.location.reload();
                 }  
                    
                
        } catch (error) {
            errornotif(error.response.data.message)
            console.log(error)
        }

            
        }

    }
    const {id}= useParams()
  useEffect(() => {
    getUser().then(
      (response) => {
        setCurrentUser(response.data);
      },
      (error) => {
        console.log("Private page", error.response);
        // Invalid token
        if (error.response && error.response.status === 403) {
          logOut();
          history.push("/login")
          window.location.reload();
        }
      }
    );

    getTransactionByAppServices(id).then((response)=>{
      console.log(response)
        setAppHistory(response.data.appTransaction)
        
    }).catch((error)=>{

        errornotif(error.response.data.message  )
})

  },[appHistory,id,history,setCurrentUser]);

  return (
    <section className="home_contents">
      <Header/>
          <div class="columns is-variable is-0 mt-4 "> 
          
               <SideMenu currentPage='transaction-history'/>

            <div class="column is-10-desktop  is-12-tablet  is-12-mobile ">
                <div class="p-1">
                  <div class="columns is-variable is-desktop   is-flex-direction-column	is-align-items-center	  has-shadow">
                    
                    <div class="column is-12 is-flex  is-flex-wrap-wrap  is-justify-content-flex-start	">
                      <h1 class="title is-size-4	">
                         Historique des transactions 
                      </h1>
                         

                    </div>
               
                      <div class="table-container  is-11-desktop column mt-3 ">
                                <table id="" class=" table table-bordered" >
                                <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th>Numéro du client</th>
                                      <th>Montant</th>
                                      <th>Statut</th>
                                      <th>Date</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                {(appHistory.length > 0 )? 
                                  <tbody>
                                
                                 

                                    {appHistory.map((appHistory,index) => (
                                         <tr key={appHistory.id}  className="odd">
                                            <td data-label="Id">{index}</td>
                                            <td data-label="Numéro du client">{appHistory.clientNumber}</td>
                                            <td data-label="Montant">{appHistory.amount} FCFA</td>
                                            <td data-label="Statut"> {appHistory.status ? <span className='has-text-success'>Réussie</span> :<span className='has-text-danger'>Échoué</span> }</td>
                                            <td data-label="Date">{dateFormat(appHistory.createdAt, "dd/mm/yyyy : H:MM")}</td>
                                            <td>
                                                <NavLink  to="#"  onClick={()=>{handleDelateTransaction(appHistory.id)}}><span class="icon-text has-text-dark">  <span class="icon"> <FontAwesomeIcon icon={faTrash}/></span></span> </NavLink>  
                                            </td>
                                            </tr>

                                      ))
                                    }
                                      
                                  </tbody>
                             :<tr> Pas de transaction</tr>}
                              </table>   
                            
                            
                          

                    </div>

                  </div>

              
            
                </div>
            </div>
          </div>



<div className=''>
      <Footer /></div>
    </section>
  )
}

export default TransactionHistoryApp;