import React, { Component } from "react";
import './FAQ.css';
import '../../../assets/js/index';
import Header from '../../Layouts/Header';
import Footer from "../../Layouts/Footer";



class Faqs extends Component {
    state ={
        content_1:"block",
        content_2:"block",
        content_3:"block",
        content_4:"block",
        content_5:"block",
    }

    showHiddenTools =() => {
        if (this.state.content_1 === "none"){
            this.setState({
                content_1:"block"
            })
        }
        else {
            this.setState({
                content_1:"none"
            })
        }
    }

    showHiddenTools2 =() => {
        if (this.state.content_2 === "none"){
            this.setState({
                content_2:"block"
            })
        }
        else {
            this.setState({
                content_2:"none"
            })
        }
    }

    showHiddenTools3 =() => {
        if (this.state.content_3 === "none"){
            this.setState({
                content_3:"block"
            })
        }
        else {
            this.setState({
                content_3:"none"
            })
        }
    }
    showHiddenTools4 =() => {
        if (this.state.content_4 === "none"){
            this.setState({
                content_4:"block"
            })
        }
        else {
            this.setState({
                content_4:"none"
            })
        }
    }
    showHiddenTools5 =() => {
        if (this.state.content_5 === "none"){
            this.setState({
                content_5:"block"
            })
        }
        else {
            this.setState({
                content_5:"none"
            })
        }
    }

    render() {
        return (

            <section>
                <Header />
                <main>
                    <h1 class="headerH1TextStyle">Foire aux questions</h1>
                    <ul id="accordion">
                        <li>
                            <button aria-controls="content-1" aria-expanded="false" className={(this.state.content_1==='none') ? "active" : ""}
                                onClick={this.showHiddenTools} id="accordion-control-1">
                                Qu'est ce que c'est le Mobile Money (MoMo)?
                            </button>
                            <div class="acc-item-content visualEffectDivShade" aria-hidden="false" id="content-1" className={(this.state.content_1==='none') ? "d-block" : "d-none"}>
                                <br />
                                <p> MTN Mobile Money est un moyen pratique,
                                    sécurisé et facile pour des transferts de l’argent,
                                    acheter du crédit, payer ses factures,
                                    faire son réabonnement Canal Plus  en utilisant son téléphone mobile.
                                    Ouvrez un compte MTN Mobile Money et bénéficiez des services sûrs et abordables.</p>
                            </div>
                        </li>

                        <li>
                            <button aria-controls="content-2" aria-expanded="false" className={(this.state.content_2==='none') ? "active" : ""}
                                onClick={this.showHiddenTools2} id="accordion-control-2">Quel service nous offre le paiement par Mobile Money (MoMo) ?</button>
                            <div class="acc-item-content visualEffectDivShade" aria-hidden="true" id="content-2" className={(this.state.content_2==='none') ? "d-block" : "d-none"}>
                                <br />
                                <p>Payer vos factures</p>
                            </div>
                        </li>

                        <li>
                            <button aria-controls="content-3" aria-expanded="false" className={(this.state.content_3==='none') ? "active" : ""}
                                onClick={this.showHiddenTools3} id="accordion-control-3">Est-ce que le paiement par Mobile Money (MoMo) est sécurisé ?</button>
                            <div class="acc-item-content visualEffectDivShade" aria-hidden="true" id="content-3" className={(this.state.content_3==='none') ? "d-block" : "d-none"}>
                                <br />
                                <p>MTN Mobile Money (MoMo) demande à chaque utilisateur la création d’un code PIN à l'ouverture du compte qui est composé de cinq (05) chiffres qui est secret et strictement personnel, à ne pas communiquer.
                                    Permettant ainsi aux utilisateurs de 	confirmer les transactions par eux même d’où la sécurité de paiement par Mobile Money</p>
                            </div>
                        </li>

                        <li>
                            <button aria-controls="content-4" aria-expanded="false" className={(this.state.content_4==='none') ? "active" : ""}
                                onClick={this.showHiddenTools4} id="accordion-control-4">Que faire si on ne possède pas un Mobile Money (MoMo) ?</button>
                            <div class="acc-item-content visualEffectDivShade" aria-hidden="true" id="content-4" className={(this.state.content_4==='none') ? "d-block" : "d-none"}>
                                <br />
                                <p>Dans ce cas nous proposons à l’utilisateur deux options qui sont : <br />

                                    - Ouvrir un compte Mobile Money (MoMo) personnel <br />

                                    - Utiliser le compte Mobile Money (MoMo) d’une personne que vous connaissez</p>
                            </div>
                        </li>

                        <li>
                            <button aria-controls="content-5" aria-expanded="false" className={(this.state.content_5==='none') ? "active" : ""}
                                onClick={this.showHiddenTools5} id="accordion-control-5">Comment être sûr que le paiement a été effectué avec succès?</button>
                            <div class="acc-item-content visualEffectDivShade" aria-hidden="true" id="content-5" className={(this.state.content_5==='none') ? "d-block" : "d-none"}>
                                <br />
                                <p>Après chaque transaction MTN a l’habitude d’envoyer aux utilisateurs
                                    un message qui notifie la somme payée, le solde restant et l’ID de la transaction
                                    avec la date et l’heure et en dehors de cela quand le paiement est effectué
                                    le site vous redirige vers le site de départ qui à son tour vous notifie
                                    le succès de la prise de commande par conséquent le paiement est effectué</p>
                            </div>
                        </li>
                    </ul>
                </main>
                <br />
                <br />
                <Footer />
            </section>

        );
    }
}
export default Faqs;