import React, {useState} from 'react';
import AddApplicationContext from '../../../../contexts/addApplicationContext.js';
import FormOne from './FormOne';
import FormTwo from './FormTwo';
import FormThree from './FormThree'

import FormFinish from './FormFinish';
import ProgressBar from './ProgressBar';
import './StepForm.css'


const StepForm = () => {

    const [step,setStep] = useState(0);
    const [appName, setAppname] = useState(null);
    const [appUrl, setAppUrl] = useState(null);
    const [platform, setPlatForm] = useState(null);
    const [transactionNumber, setTransactionNumber] = useState(null);

    const addApplicationDetails = {
        step:step,
        appName:appName,
        appUrl:appUrl,
        platform:platform,
        transactionNumber:transactionNumber,
        setStep,
        setAppname,
        setAppUrl,
        setPlatForm,
        setTransactionNumber
    };
   

    
    return (
        <AddApplicationContext.Provider value={{addApplicationDetails}}>

              

                    <div className="widzard">
                     <ProgressBar />
                      
                        {step === 0 && <FormOne /> }
                        {step === 1 && <FormTwo /> }
                        {step === 2 && <FormThree /> }

                        {step === 3 && <FormFinish /> }  
                
                     
                </div>
                      
       
                                
                             

                                
                         
            
{/**
 
  <form role="form" action="index.html" class="login-box">
                                                        <div class="tab-content" id="main_form">
                                                            <div class="tab-pane active" role="tabpanel" id="step1">
                                                                <h4 class="text-center">Step 1</h4>
                                                                <div class="row">
                                                                {step === 0 && <FormOne /> }
                                                                {step === 1 && <FormTwo /> }
                                                                {step === 3 && <FormFinish /> }  
                                                                
                                                                    
                                                                    
                                                                </div>
                                                            
                                                            </div>
                                                    
                                                        
                                                            <div class="clearfix"></div>
                                                        </div>
                                                        
                                </form>


 */}



        </AddApplicationContext.Provider>
    );
};

export default StepForm;