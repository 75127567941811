import React, {useState } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import {useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleNotch}  from '@fortawesome/free-solid-svg-icons';
import Checkresetmail from "./Checkresetmail";
import {successnotif,errornotif} from  '../../../utils/notification'
import { passwordResetService } from "../../../services/Auth";
import { NavLink } from "react-router-dom";



const PasswordReset=()=>{

    const { register,handleSubmit,trigger,reset,watch,formState: {errors,isDirty, isSubmitting ,isSubmitSuccessful,isValid},} = useForm();
    const [loading,setLoading]= useState(isSubmitting)
    const [formIsSuccessfullsubmit,setFormIsSuccessfullsubmit]= useState(false)

    const [flahSuccess,setFlashSuccess]= useState('')
    const [flahError,setFlashError]= useState('')

    const [redirectUrl,setRedirectUrl]= useState('')
  


    const onSubmit = async(data)=>{
            setLoading(true)
         
         try{    
            //Utiliser passwordReset de auth service
            const response = await passwordResetService(data.email)
            reset()
            //if(response.status === 201) setFlashSuccess(response.data.message)
            setLoading(false)
            successnotif(response.data.message)
            setInterval(() => {
                if(response.status===200)setFormIsSuccessfullsubmit(true)
            },3000);  
             
           }catch (error) {
            setLoading(false)
            setFormIsSuccessfullsubmit(false)
            errornotif(error.response.data.message)     
        }  

    }

    return(
        <section className="home_contents">
         <Header/>
         {!formIsSuccessfullsubmit ?  
            <div class="container">
                  <div class="colomuns is-flex is-justify-content-center mt-1 is-align-content-center mt-6">
                <div class="column   is-12-mobile is-12-tablet is-5-desktop card">
                    
                            <h3 className="has-text-dark	 is-size-4 is-uppercase	has-text-centered has-text-weight-bold 	">Réinitialisez votre mot de passe</h3>
                
                        <div className="card-content">
                        <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form">
                                <h5>Entrez l'adresse e-mail vérifiée de votre compte utilisateur et nous vous enverrons un lien de réinitialisation de mot de passe.</h5>

                                    <div className="input_field">
                                        <label>Votre Email :<span className="isrequired">(*)</span></label>
                                        <div className="control">  
                                        <input type='email' class="input"
                                        
                                        className={`input  ${errors.email && 'error'}`}
                                        {...register("email", {
                                            required: 'L\'email est réquis',
                                            pattern : {
                                                value :/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                message :'Votre email est invalid'
                                            } 

                                        })}
                                        onKeyUp={()=>{
                                            trigger("email");
                                        }}
                                        />
                                    
                                    {errors.email && <small className="error">{errors.email.message}</small> }
                                    </div>
                                    </div>
                                    <div className="inputfield">

                                    <button type='submit' className={`button  is-fullwidth is-success mt-4 ${!isValid? 'griser' : null} ${loading? 'is-loading' : null}`} >Demander la réinitialisation </button>
                                    </div>
                                </div>
                        </form>
                        </div>
    
                </div>
          </div>


          
            </div>
            :<Checkresetmail/>}
         <Footer />
      </section>       

    );
}
export default PasswordReset;