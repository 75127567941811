import React, {useContext, useState} from 'react';
import AddApplicationContext from '../../../../contexts/addApplicationContext.js';
import {addAppService} from '../../../../services/userApplication.service';
import succesLogo from "../../../../img/success.svg";
import {successnotif,errornotif} from  '../../../../utils/notification'


const FormFinish = () => {

    const addApplicationContext = useContext(AddApplicationContext);
    const updateContext = addApplicationContext.addApplicationDetails;
    const [loading,setLoading]= useState(false)
    const prev = () => {
        updateContext.setStep(updateContext.step - 1)
    };
    const next = () => {
        updateContext.setStep(updateContext.step + 1)
    };

    const addApp = async() => {
           setLoading(true)
         try{       
                
                //Ajouter l'app
                const response = await addAppService(updateContext)
            
                successnotif(response.data.message)
                setInterval(() => {
                    if(response.status===201) next()
                    setLoading(false)
                }, 2000);    
               
            }catch (error) {
                errornotif(error.response.data.message)
                setLoading(false)

        }  


    };

    const finish = () => {
        console.log(updateContext);
    }
    return (
              <div className='form widzard-items'>          

                    <div className='head'>
                        <h4 className='title is-size-5-tablet is-size-4-desktop is-size-5-mobile'>Détails de la configuration</h4>
                    </div>
                   
                    <div className='succesBody'>
                        <h5 className='is-size-4	'>Informations sur l'application</h5>
                        <hr/>
                        <ol>
                            <li> <strong>Nom de l'application :</strong>&ensp;{updateContext.appName} </li>
                            <li><strong>Url de l'application : </strong>&ensp;{updateContext.appUrl} </li>
                            <li> <strong>Plateforme  : </strong> &ensp;{updateContext.platform}  </li>
                            <li> <strong>Numéro de transaction:</strong>&ensp;{updateContext.transactionNumber} </li>

                        </ol>
                        <hr/>
                      

                    </div>
                   

                    <div className='widzard-footer'>
                      <div className='widzard-btn'>
                    <div className='prev'>
                         <button type="button" className="button is-info prev" onClick={prev} >Précédent</button>
                    </div>
                    <div className='next'>
                    <button type='submit'  className={`button is-primary   next ${loading? 'is-loading' : null}` } onClick={addApp}>Valider</button>

                    </div>
            </div>   
        </div>   
             </div>
        
    );
};

export default FormFinish;
