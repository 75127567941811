import React, { Component, useContext, useEffect, useRef, useState } from "react";
import website from "../../img/website.png";
import './header.css';
import { NavLink } from "react-router-dom";
import {logOut} from '../../services/Auth';
import Auth from "../../contexts/Auth";
import UserContext from "../../contexts/UserContext";
import SideMenuIsvisibleContext from  '../../contexts/sideMenuIsvisibleContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDashboard,faUserCircle,faRightFromBracket,faUser}  from '@fortawesome/free-solid-svg-icons';
const Header = ()=>  {
    const {currentUser} =  useContext(UserContext)
    const {isAuthenticated,setIsAuthenticated} =  useContext(Auth)
    const [isActive, setisActive] = useState(false);
    const profilmenu = useRef(null)

    const sideMenuIsvisibleContext = useContext(SideMenuIsvisibleContext);
    const updateSideMenuIsvisible = sideMenuIsvisibleContext.sideMenuIsvisibleDetails;
  
    const sideMenuIsvisible =updateSideMenuIsvisible.sideMenuIsvisible
    const setSideMenuIsvisible = updateSideMenuIsvisible.setSideMenuIsvisible
    
    const  handleProfileClick = ()=>{

            const toggleMenu = profilmenu.current
            toggleMenu.classList.toggle('active')
    }
   const toggleBurgerMenu = ()=>{
         setisActive(!isActive);
    }
   const toggleSideMenu = ()=>{
        setSideMenuIsvisible(!sideMenuIsvisible)
    }
    const handleLogout  = ()=>{
        logOut()
        setIsAuthenticated(false)

    }

useEffect(()=>{
    console.log(sideMenuIsvisible) 
},[])

  
        return (
        
            <nav class="navbar   has-shadow">
              <div class="navbar-brand">
                    <div class="navbar-burger ">
                        <NavLink to="#" role="button"  aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"   onClick={()=>{toggleSideMenu()}} >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        </NavLink>
                    </div> 
                    <div class="navbar-item is-expanded" >  
                       
                        <NavLink  to="/accueil">
                            <img id="imgShape" src={website}  width="" height="" alt="" />
                        </NavLink>
                    </div> 
                    <NavLink  to='#' role="button"  class="navbar-burger nav-toggler"  aria-label="menu" aria-expanded="false" onClick={()=>{toggleBurgerMenu()}}   >
                        <span aria-hidden="true"><FontAwesomeIcon className="fa-lg" icon={ faUserCircle}/></span>
                       
                    </NavLink>
                </div>

                    <div class={` navbar-menu has-background-white  ${isActive ? "is-active" :""}`} >
                    
                    <div class="navbar-end">
                      <div class="navbar-item has-dropdown is-hoverable">
                            <NavLink to="#" class="navbar-link is-uppercase	">
                            {currentUser.name} &ensp; &ensp;<FontAwesomeIcon className="fa-lg" icon={ faUser}/>

                            </NavLink>
                        <div class="navbar-dropdown is-right">

                             <NavLink class="navbar-item" to="/profil"> <span class="icon-text has-text-dark">  <span class="icon"> <FontAwesomeIcon className="fa-lg" icon={ faUserCircle}/></span></span>&ensp;Mon profil</NavLink>
                            <hr class="navbar-divider" />
                            <NavLink to="#" class="navbar-item" onClick={handleLogout}> <span class="icon-text has-text-dark">  <span class="icon"> <FontAwesomeIcon className="fa-lg" icon={ faRightFromBracket}/></span></span> &ensp; Se déconnecter</NavLink>
                        </div>
                        </div>
                    </div>
                    </div>
              </nav>
        )
    
}


export default Header;