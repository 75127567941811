import React, { useContext, useEffect, useState } from 'react';
import { set, useForm } from "react-hook-form";
import Header from '../../../Layouts/headerDashbord';
import Footer from '../../../Layouts/Footer';
import SideMenu from '../SideMenu';
import { getAppByIdServices, updateAppServices } from '../../../../services/userApplication.service';
import { errornotif, successnotif } from '../../../../utils/notification';
import { getUser } from '../../../../services/user.service';
import UserContext from '../../../../contexts/UserContext';
import { logOut } from '../../../../services/Auth';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';



const EditAppConfig = (data) => {
            const { register,onChange,handleSubmit,trigger,reset,watch,formState: {errors,isDirty, isSubmitting ,isSubmitSuccessful,isValid},} = useForm({
                defaultValues: {
                    appName:"",
                    appUrl:"",
                    platform:"",
                    transactionNumber:""
                }
             });
const [loading,setLoading]= useState(isSubmitting)
const {id}= useParams()
 const {currentUser,setCurrentUser} = useContext(UserContext)
 const [app,setApp] = useState({})
 const history = useHistory()
   
            const onSubmit = async(data)=>{

                try {
                    const response = await updateAppServices(id,data)
                    successnotif(response.data.message)
                    //window.location.reload();
                  } catch ({response}) {
                    //console.log(response.data.message)
                    errornotif(response.data.message)
                  }
              
            }


            useEffect(() => {
                getUser().then(
                  (response) => {
                    
                    setCurrentUser(response.data);
                    reset(response.data)
                  },
                  (error) => {
                    console.log("Private page", error.response);
                    // Invalid token
                    if (error.response && error.response.status === 403) {
                      logOut();
                      history.push("login")
                      window.location.reload();
                    }
                  }
                );
              },[]);
            
            useEffect(()=>{
                getAppByIdServices(id).then((response)=>{
                    setApp(response.data)
                    reset(response.data)
                }).catch((error)=>{
                    errornotif(error.response.data.message)
             
                        history.push("/my-applications")
                     
                })       
            },[id])


    return (
        <section className="home_contents">
             <Header/>
   
             <div class="columns is-variable is-0 mt-4 "> 
               <SideMenu currentPage='my-applications'/>
            <div class="column is-10-desktop  is-12-tablet  is-12-mobile ">
                <div class="p-1">
                  <div class="columns is-variable is-desktop   is-flex-direction-column	is-align-items-center	  has-shadow">
                    
                    <div class="column is-12 is-flex  is-flex-wrap-wrap  is-justify-content-space-around	">
                          <h4 class="title  is-4 is-size-5-mobile ">
                             Modifier les configurations
                          </h4>
                        <div>
                        </div>    
                    </div>
               
                      <div class=" is-7-desktop is-12-mobile is-12-tablet column mt-3 ">
                        <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form">
                                    <div className="input_field">
                                        <label>Nom de l'application: <span className="isrequired">(*)</span></label>
                                        <div className="control">  
                                            <input 
                                                type='text' 
                                                className={`input  ${errors.appName && 'error'}`}
                                                {...register("appName",{
                                                    required: 'Le Nom de l\'entreprise est réquis', 
                                                    
                                                })}
                                                onKeyUp={()=>{
                                                    trigger("appName");
                                                }}
                                            
                                            /> 
                                            {errors.appName && <small className="error">{errors.appName.message}</small> }
                                        </div>
                                    </div>
                                    
                                    <div className="input_field">
                                        <label>Lien de la boutique: <span className="isrequired">(*)</span></label>
                                        <div className="control">  
                                            <input 
                                                type='text' 
                                                className={`input  ${errors.appUrl && 'error'}`}
                                                {...register("appUrl",{
                                                    required: 'Le lien de la boutique  est réquis', 
                                                    pattern : {
                                                    value :/((http|https)\:\/\/||www)[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/,
                                                    message :'Le  lien est invalid'
                                                } 
                                                })}
                                                onKeyUp={()=>{
                                                    trigger("appUrl");
                                                }}
                                            
                                            /> 
                                            {errors.appUrl && <small className="error">{errors.appUrl.message}</small> }
                                        </div>
                                    </div>

                                    <div className="input_field">
                                        <label>Plateforme (CMS): <span className="isrequired">(*)</span></label>
                                        <div className="control">  
                                            <select 
                                                type='text' 
                                                className={`input  ${errors.platform && 'error'}`}
                                                {...register("platform",{
                                                    required: 'Ce champ  est réquis', 
                                                    
                                                })}
                                                onKeyUp={()=>{
                                                    trigger("platform");
                                                }}
                                            >
                                            <option value="">Sélectionner...</option>
                                            <option value="Prestashop">Prestashop</option>
                                            <option value="Wordpress (woocommerce)">Wordpress (woocommerce)</option>
                                            <option value="Shopify">Shopify</option>
                                                
                                        </select>
                                            {errors.platform && <small className="error">{errors.platform.message}</small> }
                                        </div>
                                    </div>
                                    <div className="input_field">
                                        <label>Numéro de réception Mobile money: <span className="isrequired">(*)</span></label>
                                        <div className="control">  
                                            <input 
                                                type='tel' 
                                                className={`input  ${errors.transactionNumber && 'error'}`}
                                                {...register("transactionNumber", {
                                                    required: 'Le numéro de réception est réquis',
                                                    pattern : {
                                                        value :/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
                                                        message :'Numéro de  réception invalid'
                                                    }  
                                                })}
                                                onKeyUp={()=>{
                                                    trigger("transactionNumber");
                                                }}
                                            />
                                            {errors.transactionNumber && <small className="error">{errors.transactionNumber.message}</small> }
                                        </div>
                                    </div>
                                
                                
                                    <div className="inputfield mt-2">
                                        <button type='submit' className={`button is-primary  ${!isValid? 'griser' : null}`} >  Modifier </button>
                                    </div>
                                </div>
                         </form>
                         </div>
                     </div>

              
            
                </div>
            </div>
              </div>
         
    <Footer />
    </section>
     );
};

export default EditAppConfig;