import axios from "axios";
import authHeader from "./auth-header";


export const getUser = () => {
  return axios.get(process.env.REACT_APP_SERVER_URL+"/api/user/currentUser", { headers: authHeader() });
};

export const getUserAccountStatisticsService = () => {
  return axios.get(process.env.REACT_APP_SERVER_URL+"/api/user/getUserAccountStatistics", { headers: authHeader() });
};


export const updateUser = (data)=>{
  const userData ={
    email:data.email,
    phone:data.phone,
    name:data.name
  }
return  axios.post(process.env.REACT_APP_SERVER_URL+'/api/user/updateUser',userData,{ headers: authHeader() })
}
