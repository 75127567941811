import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Layouts/headerDashbord';
import Footer from '../../Layouts/Footer';
import Auth from '../../../contexts/Auth';
import {getUser, getUserAccountStatistics, getUserAccountStatisticsService} from '../../../services/user.service';
import {logOut} from '../../../services/Auth';
import UserContext from '../../../contexts/UserContext';
import { useHistory } from 'react-router-dom';
import SideMenuIsvisibleContext from '../../../contexts/sideMenuIsvisibleContext'
import { NavLink } from 'react-router-dom';
import SideMenu from './SideMenu';
import BarChart from '../Chart/BarChart'
import LineChart from '../Chart/LineChart';

const Dashbord = () =>{

  const {isAuthenticated} =  useContext(Auth)
  const {currentUser,setCurrentUser} = useContext(UserContext)
  const sideMenuIsvisibleContext = useContext(SideMenuIsvisibleContext);
  const updateSideMenuIsvisible = sideMenuIsvisibleContext.sideMenuIsvisibleDetails;
  const sideMenuIsvisible =updateSideMenuIsvisible.sideMenuIsvisible
  const [userstatistic,setUserStatistic]= useState([])
  const history = useHistory();

  useEffect(() => {
    getUser().then(
      (response) => {
        //envoyer le current user dans notre contexte
  
       setCurrentUser(response.data)
      },
      (error) => {
        console.log("Private page", error.response);
        // Invalid token
        if (error.response && error.response.status === 403) {
          logOut();
          history.push("login")
          window.location.reload();
        }
      }
    );
 
  },);

  useEffect(()=>{
    getUserAccountStatisticsService().then((response=>{
        setUserStatistic(response.data)
    })).catch((error)=>{
      setUserStatistic([0,0,0])

  })

  console.log(userstatistic)
  },[userstatistic])
 

  return (
    <section className="home_contents">
          <Header/>
     
            <div class="columns is-variable is-0 mt-4 " > 
                <SideMenu currentPage='dashboard'  />
               <div class="column is-10-desktop  is-12-tablet  is-12-mobile ">
                  <div class="p-1">
                      <div class="columns is-variable is-desktop">
                        <div class="column">
                          <h6 class=" is-size-4-desktop is-size-6-mobile is-size-5-table">
                            Bonjour {currentUser.name} bienvenue sur Emobpay 
                          </h6>
                        </div>
                      </div>
                      <div class="columns  is-variable">
                      <div class="column is-4-tablet">
                          <div class="card has-background-primary has-text-white">
                            <div class="card-header">
                              <div class="card-header-title has-text-white is-uppercase">
                                Nombre d'application
                              </div>
                            </div>
                            <div class="card-content">
                              <p class="is-size-3">{userstatistic.usersApplicationCount}</p>
                            </div>
                          </div>
                        </div>
                        <div class="column is-4-tablet">
                          <div class="card has-background-primary has-text-white">
                            <div class="card-header">
                              <div class="card-header-title has-text-white is-uppercase">
                               Applications actives

                              </div>
                            </div>
                            <div class="card-content">
                              <p class="is-size-3">{userstatistic.usersApplicationActifCount}</p>
                            </div>
                          </div>
                        </div>
                       
                        <div class="column is-4-tablet">
                          <div class="card has-background-primary has-text-white">
                            <div class="card-header">
                              <div class="card-header-title has-text-white is-uppercase">
                                Montant total de transaction
                              </div>
                            </div>
                            <div class="card-content">
                              <p class="is-size-3">{userstatistic.payementTotal} XOF</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns is-variable">
                        <div class="column is-12 mt-1">
                          <article class="message is-primary">
                            <div class="message-header">
                              <p>Démarrage rapide</p>
                            </div>
                            <div class="message-body">
                              <h5 className='is-size-5'>Configurez Emobpay</h5>
                              <p>Apprenez comment intégrer Emobpay à votre business</p>
                              <p>Notre documentation contient les explications dont vous avez besoin pour integrer Emobpay à votre site web ou application.</p>
                              <div>
                                  <NavLink to='#' class="button is-primary"> Documentation Emobpay</NavLink>
                              </div>
                            </div>
                          </article>
                        </div>
          
                     </div>
                  </div>
              </div>
            </div>
      <Footer />
    </section>
  )
}

export default Dashbord;