import React, {useContext, useEffect} from 'react';
import AddApplicationContext from '../../../../contexts/addApplicationContext.js';
import { set, useForm } from "react-hook-form";

const FormTwo = () => {
    const { register,handleSubmit,trigger,reset,watch,formState: {errors,isDirty, isSubmitting ,isSubmitSuccessful,isValid},} = useForm();
    

    const addApplicationContext = useContext(AddApplicationContext);
    const updateContext = addApplicationContext.addApplicationDetails;

        const next = () => {
            updateContext.setStep(updateContext.step + 1)
        };
        const prev = () => {
            updateContext.setStep(updateContext.step - 1)
        };

        const handleChangeTransactionNumber = (e)=> {
            updateContext.setTransactionNumber(e.target.value)
        }
        console.log(updateContext);

    const onSubmit = async(data)=>{

        if(isValid ){
             next();
        }
             
      }

  
    return (
        <div className='form widzard-items'>          
    <form  onSubmit={handleSubmit(onSubmit)}>
        <div className='head'>
            <h4 className='title is-size-5-tablet is-size-4-desktop is-size-5-mobile'>Détails de configurations</h4>
        </div>

        <div className="input_field">
            <label>Numéro de transaction: <span className="isrequired">(*)</span></label>
            <div className="control">  
                <input 
                    type='tel' 
                    className={`input  ${errors.transactionNumber && 'error'}`}
                    {...register("transactionNumber", {
                    required: 'Le numéro de transaction est réquis',
                        pattern : {
                            value :/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
                            message :'Numéro  invalid'
                            }  
                         })}
                        onKeyUp={(e)=>{
                        trigger("transactionNumber");
                        handleChangeTransactionNumber(e)

                        }}
                     
                        />
                     {errors.transactionNumber && <small className="error">{errors.transactionNumber.message}</small> }
               </div>
        </div>
           <div className='widzard-footer'>
           <div className='widzard-btn'>
                    <div className='prev'>
                         <button type="button" className="button is-info   prev" onClick={prev} >Précédent</button>
                    </div>
                    <div className='next'>
                    <button type='submit'  className="button is-primary  next">Suivant</button>
                    </div>
            </div>   
        </div>      
    </form>              

</div>
    );
};

export default FormTwo;