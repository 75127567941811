import './Home.css';
import Footer from "../../Layouts/Footer";
import Header from "../../Layouts/Header";
import IMask from 'imask';
import React from 'react';
import axios from 'axios';
import padLock from '../../../img/padlock.png'
import { initPayementServices } from '../../../services/userTransaction.services';
import {successnotif,errornotif} from  '../../../utils/notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner,faTruckLoading}  from '@fortawesome/free-solid-svg-icons';
import loader from "./loader.svg"
class Home extends React.Component {

  state = {
    amount: 0,
    token: '',
    numero: '',
    exchangeKey:'',
    message: "",
    isWaiting: false,
    returnUrl: '',
    back_link: '',
    techno_site: '',
    mask: '+{229}(00)00-00-00',
    returnProcess : false
  }

  componentDidMount() {

    var ReverseMd5 = require('reverse-md5');
    var rev = ReverseMd5({
      lettersUpper: false,
      lettersLower: true,
      numbers: true,
      special: false,
      whitespace: true,
      maxLen: 12
    });

    // var url = new URL( window.location.href);
    const query = new URLSearchParams(this.props.location.search);
    this.setState({isWaiting: false});
    this.setState({token: query.get('token')});
    this.setState({amount: query.get('data')});
    this.setState({exchangeKey: query.get('exchangeKey')});
    this.setState({returnUrl: decodeURI(query.get('path'))});
    this.setState({techno_site: query.get('techno')});


    let returnLink = document.getElementById("returnLink");
    returnLink.style.display = "none";
  }

  handleChange = event => {
    this.setState({ numero: event.target.value });
    var element = document.getElementById('maskin');
    var maskOptions = {
      mask: '00 00 00 00'
    };
    var mask = IMask(element, maskOptions);
    this.setState({ mask: mask });

  }
  showMessage = event => {
    event.preventDefault();
    this.setState({ message: "error" });
  }
  returnStore = event => {
    this.setState({ returnProcess: true });
    window.location.href = this.state.returnUrl;
  }
  handleSubmit = async(event) => {

    event.preventDefault();
    var msg = document.getElementById("message_request");

    let pay = document.getElementById("pay");
    let loading = document.getElementById("loading");

    pay.style.display = "none";
    msg.style.display = "block";
    msg.textContent = "Transaction en cours";

    loading.style.display = "block";

    msg.classList.add('alert-warning');
    
    this.setState({ isWaiting: true });

    var str = window.location.href;
    var url = new URL(str);

    //récuperer les données de url

    let payementUrl = url.search

    const amount = new URLSearchParams(payementUrl).get('data');
    const path = new URLSearchParams(payementUrl).get('path');
    const techno = new URLSearchParams(payementUrl).get('techno');
    const exchangeKey = new URLSearchParams(payementUrl).get('exchangeKey');
    const token = new URLSearchParams(payementUrl).get('token');




    //envoyer la requete de paiement
    const payementData = {
      "amount": this.state.amount,
      "currency": "XOF",
      "partyId": "229" + this.state.numero
    }

    try{
        const response = await initPayementServices(payementData,exchangeKey)
        if(response){
          this.setState({ isWaiting: false });
          var status = response.status;
          if (techno === 'Prestashop') {
            this.setState({ returnUrl: this.state.returnUrl + '&status=' + status + '&token=' + this.state.token + '&amount=' + this.state.amount + '&account=' + this.state.numero});
          }
          if (status === 200) {
            successnotif(response.data.message)
            msg.classList.remove('alert-warning');
            loading.style.display = "none";
            msg.textContent = "";
            msg.classList.remove('alert-danger');
  
            let returnLink = document.getElementById("returnLink");
            returnLink.style.display = "block";
            returnLink.style.padding = "5px";
            returnLink.style.marginTop = "40px";
            returnLink.style.borderRadius = "5px";
            returnLink.style.color = "#FFF";
            pay.style.display = "none";
            this.setState({ message: "success" });
          }

        }
    } catch (error) {
      console.log(error.response.data);
      errornotif(error.response.data.message)
      loading.style.display = "none";
      msg.textContent = "";
      msg.classList.remove('alert-danger');
      msg.classList.remove('alert-warning');
      msg.style.display = "none";

      if (techno === 'Prestashop') {
         status = error.response.status;
        this.setState({ returnUrl: this.state.returnUrl + '&status=' + status + '&token=' + token });
      }
      let returnLink = document.getElementById("returnLink");
      returnLink.style.display = "block";
      returnLink.style.padding = "5px";
      returnLink.style.marginTop = "40px";
      returnLink.style.borderRadius = "5px";
      returnLink.style.color = "#FFF";
      pay.style.display = "none";
      this.setState({ message: "error" });
    }

   /**  axios({


      method: 'post',
      url: 'https://api.dev.writer.prestashop.rintio.com/payment_request',
     
    }).then((res) => {
      this.setState({ isWaiting: false });
      var status = res.status;
      if (techno === 'Prestashop') {
        this.setState({ returnUrl: this.state.returnUrl + '&status=' + status + '&token=' + this.state.token + '&amount=' + this.state.amount + '&account=' + this.state.numero});
      }
      if (status === 200) {
        msg.classList.remove('alert-warning');
        loading.style.display = "none";
        msg.textContent = "";
        let popup_sucess = document.getElementById('popup_sucess');
        popup_sucess.style.right = "3%";
        msg.classList.remove('alert-danger');

        setTimeout(function () {
          popup_sucess = document.getElementById('popup_sucess');
          popup_sucess.style.right = "-27%";
        }, 2600);

        let returnLink = document.getElementById("returnLink");
        returnLink.style.display = "block";
        returnLink.style.padding = "5px";
        returnLink.style.marginTop = "40px";
        returnLink.style.borderRadius = "5px";
        returnLink.style.color = "#FFF";
        pay.style.display = "none";
        this.setState({ message: "success" });
      }

    }, error => {

      let popup_failed = document.getElementById('popup_failed');
      popup_failed.style.right = "3%";
      loading.style.display = "none";
      msg.textContent = "";
      msg.classList.remove('alert-danger');
      msg.classList.remove('alert-warning');
      msg.style.display = "none";

      setTimeout(function () {
        popup_failed  = document.getElementById('popup_failed');
        popup_failed.style.right = "-27%";
      }, 2600);

      if (techno === 'Prestashop') {
        var status = 500;
        this.setState({ returnUrl: this.state.returnUrl + '&status=' + status + '&token=' + token });
      }

      let returnLink = document.getElementById("returnLink");
      returnLink.style.display = "block";
      returnLink.style.padding = "5px";
      returnLink.style.marginTop = "40px";
      returnLink.style.borderRadius = "5px";
      returnLink.style.color = "#FFF";
      pay.style.display = "none";
      this.setState({ message: "error" });
    })

    */
  }

  render() {
    return (
      <section className="home_contents">
        
        <Header />
        <div class="container">
          <div class="colomuns is-flex is-justify-content-center 	is-align-content-center 	" style={{ marginTop: "1rem" }}>
            
            <div class="column   is-12-mobile is-7-tablet is-5-desktop ">
                  <form onSubmit={this.handleSubmit} >
                    <div class="card">
                      <div class="card-body">
                        <div id="form-main">
                          <div class="column  is-12-mobile is-12-tablet is-12-desktop  ">
                            <h3 class="has-text-centered title_card is-size-6-mobile	" >Paiement sécurisé par Mobile Money &nbsp; <img src={padLock} width="20" height="20" className="paiementimg" alt='' /></h3>
                          </div> <hr />

                          <div class="myImage"> </div>

                          <div id="form-item">
                             <input id="maskin" class="form-item-text" type="tel" placeholder="97000000" name="numero" onChange={this.handleChange} required />
                          </div>
                          <div id="form-item">
                            <p for="fname" class="amountShape" id="amount" >{this.state.amount} FCFA</p>
                          </div>
                              
                      
                          <div class="loading" id="loading" style={{ display: "none" , textAlign:'center'}}>
                            <img src={loader}/>
                            {/*<svg class="circular" viewBox="25 25 50 50">
                              <circle class="path" cx="50" cy="50" r="20" fill="none"  />
    </svg>*/}
                          </div>
                          <div id="form-footer">
                            <button type="submit" id="pay" onSubmit={this.showMessage} class="pay" disabled={this.state.isWaiting === true ? true : false}>Payer</button>
                            <button class="buttonLink" type="button" id="returnLink" disabled={this.state.returnProcess} onClick={this.returnStore} >
                                    Retourner à la boutique
                            </button> <br></br>
                            <div class="alert" id="message_request" role="alert" style={{ display: "none" }}></div>
                          </div>
                          <div> <p className={this.state.message === "success" ? "shows" : "hidden"}>Votre paiement a été accepté. Cliquez sur le bouton Retourner à la boutique pour valider la commande.</p></div>
                          <div> <p className={this.state.message === "error" ? "show" : "hidden"}>Votre paiement a echoué. Cliquez sur le bouton Retourner à la boutique.</p></div>
                        </div>
                      </div>
                    </div>
                  </form>
            </div>
          </div>
         
        </div>
        <Footer />
      </section>

    );
  }
}

export default Home;
