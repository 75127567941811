import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import Header from '../../Layouts/headerDashbord';
import Footer from '../../Layouts/Footer';
import Auth from '../../../contexts/Auth';
import {getUser} from '../../../services/user.service';
import {logOut} from '../../../services/Auth';
import { useHistory } from 'react-router-dom';
//import '../../../css/Table.css'
import detailsIcon from '../../../img/info.png'
import UserContext from '../../../contexts/UserContext';
import SideMenu from './SideMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDashboard,faList,faHistory,faChartLine}  from '@fortawesome/free-solid-svg-icons';
import { getAllAppServices } from '../../../services/userApplication.service';

import dateFormat, { masks } from "dateformat";



const TransactionHistory = () =>{

  const {isAuthenticated} =  useContext(Auth)
  const {currentUser,setCurrentUser} = useContext(UserContext)
  const [allApp ,setAllapp]= useState([])

  const history = useHistory()
 
  useEffect(() => {
    getUser().then(
      (response) => {
        setCurrentUser(response.data);
      },
      (error) => {
        console.log("Private page", error.response);
        // Invalid token
        if (error.response && error.response.status === 403) {
          logOut();
          history.push("login")
          window.location.reload();
        }
      }
    );

    getAllAppServices().then((response)=>{
      setAllapp(response.data)
      console.log(response)
  })
  },[]);

  return (
    <section className="home_contents">
      <Header/>
      <div class="columns is-variable is-0 mt-4 "> 
          
          <SideMenu currentPage='transaction-history'/>

            <div class="column is-10-desktop  is-12-tablet  is-12-mobile ">
                <div class="p-1">
                  <div class="columns is-variable is-desktop">
                    <div class="column">
                      <h1 class="title is-size-5-mobile	">
                         Historique des transactions 
                      </h1>
                    </div>
                  </div>
                  <div class="columns is-variable is-desktop">
                    <div class="column">
                      <h1 class=" is-size-5-mobile	">
                         Sélectionner l'application 
                      </h1>
                    </div>
                  </div>
                  <div class="columns is-variable is-desktop is-flex is-flex-wrap-wrap	 ">
                   {(allApp.length > 0 )?
                       
                       <>
                       {allApp.map((app,index) =>(
                                <div key={app.id}  class="column is-6">
                                  <div class="list has-visible-pointer-controls">
                                        <div class="list-item box">
                                          <div class="list-item-content">
                                            <div class="list-item-title  has-text-weight-semibold	has-text-primary">Application : {app.appName}</div>
                                            <div class="list-item-description 	"> Plateforme :{app.platform}</div>
                                            <div class="list-item-description	"> Lien :<NavLink to={app.appUrl} target='_blank'>{app.appUrl}</NavLink></div>

                                          </div>
          
                                          <div class="list-item-controls">
                                            <div class="buttons is-right">
                                              <NavLink   to={`/transaction-history/${app.id}`} class="button">
                                                <span class="icon is-small">
                                                  <FontAwesomeIcon class="icon" icon={faHistory}/>
                                                </span>
                                                <span className='is-size-6'>Consulter l'historique</span>
                                              </NavLink>
          
                                            
                                            </div>
                                          </div>
                                        </div>
          
          
                                  </div>
                              </div>    
                         ))}
                        
                      </>

                                    
                   : <div  class="column is-12">
                   <div class="list has-visible-pointer-controls">
                         <div class="list-item box">
                           <div class="list-item-content">
                             <div class="list-item-description 	">Pas d'application</div>

                           </div>

                 
                         </div>


                   </div>
               </div>  }
                   
                     
                  </div>
            
            
                </div>
            </div>
          </div>


  
       <Footer />

 
    </section>
  )
}

export default TransactionHistory;