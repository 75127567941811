import axios from "axios";
import authHeader from "./auth-header";



export const initPayementServices = (data,exchangeKey) => {
  return axios.post(process.env.REACT_APP_SERVER_URL+`/api/payement/init`,data,{headers:{exchangeKey}});
};

  export const getTransactionByAppServices = (id) => {
    return axios.get(process.env.REACT_APP_SERVER_URL+`/api/payement/getTransactionById/${id}`,{ headers: authHeader()});
  };

  export const deleteOneTransactionServices = (transactionId) => {
    return axios.delete(process.env.REACT_APP_SERVER_URL+`/api/payement/deleteOneTransaction/${transactionId}`,{ headers: authHeader()});
  };
  