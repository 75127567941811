import React, { Component, useContext, useEffect, useRef, useState } from "react";
import website from "../../img/website.png";
import profileuser from "../../img/profiluser.png"
import edit from "../../img/edit.png"
import dashbord from "../../img/dashbord.png"
import logout from "../../img/log-out.png"
import question from "../../img/question.png"
import user from "../../img/user.png"
import './header.css';
import { NavLink } from "react-router-dom";
import {logOut} from '../../services/Auth';
import Auth from "../../contexts/Auth";
import UserContext from "../../contexts/UserContext";


const Header = ()=>  {
    const {currentUser} =  useContext(UserContext)
    const {isAuthenticated,setIsAuthenticated} =  useContext(Auth)
    const [isActive, setisActive] = useState(false);
    const profilmenu = useRef(null)
  
 const  handleProfileClick = ()=>{
        const toggleMenu = profilmenu.current
        toggleMenu.classList.toggle('active')
   }

   const toggleBurgerMenu = ()=>{
         setisActive(!isActive);
   }

    const handleLogout  = ()=>{
        logOut()
        setIsAuthenticated(false)

    }



  
        return (
            <nav class="navbar has-shadow " role="navigation" aria-label="main navigation">
               <div class="navbar-brand">
                    <div class="navbar-burger ">    
                        <NavLink  to='#' role="button"  class="navbar-burger nav-toggler"  aria-label="menu" aria-expanded="false"  onClick={()=>{toggleBurgerMenu()}}  >
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </NavLink>
                    </div> 
                 
                    <div class="navbar-item is-expanded" >  
                    <NavLink  to="/accueil">
                            <img id="imgShape" src={website}  width="" height="" alt="" />
                        </NavLink>
                    </div> 
                
                </div>

                    <div class={` navbar-menu has-background-white  ${isActive ? "is-active" :""}`} >
                    
                    <div class="navbar-end">
                          <NavLink to="mentions_legales" class="navbar-item">Mentions-legales </NavLink>
                            <NavLink class="navbar-item" to="faq"> FAQ </NavLink>
                            <div class="navbar-item">
                                <div class="buttons">
                                    <NavLink to="/login" className='button is-primary' id='f'> ESPACE MARCHAND</NavLink>
                                </div>
                            </div>
                    </div>
                    </div>
              </nav>
    
           /** <nav class="navbar has-shadow " role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <div class="navbar-burger ">
                        <NavLink to="#" role="button"  aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={()=>{toggleBurgerMenu()}}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        </NavLink>
                    </div> 
                    <div class="navbar-item is-expanded" >  
                        <NavLink  to="/accueil">
                            <img id="imgShape" src={website}  width="" height="" alt="" />
                        </NavLink>
                    </div> 
                </div>
                <div id="navbarBasicExample" class={` navbar-menu ${isActive ? "is-active" :""}`}>
                    <div class="navbar-end">
                     {(!isAuthenticated && (
                          <>
                            <NavLink to="mentions_legales" class="navbar-item">Mentions-legales </NavLink>
                            <NavLink class="navbar-item" to="faq"> FAQ </NavLink>
                            <div class="navbar-item">
                                <div class="buttons">
                                    <NavLink to="/login" className='button is-primary' id='f'> ESPACE MARCHAND</NavLink>
                                </div>
                            </div>
                         </>
                        ) ) || (
                            <>
                                <NavLink to="/my-applications" class="navbar-item">Mes applications</NavLink>
                                <NavLink to="/transaction-history"  class="navbar-item">Historique des transactions</NavLink>
                                <div class="navbar-item has-dropdown is-hoverable">
                                    <a class="navbar-link">
                                       <img id="" src={profileuser} alt="" />
                                    </a>

                                    <div class="navbar-dropdown is-right profilMenu">
                                             <div className="is-flex flex-wrap is-justify-content-space-between	">
                                                 <div>
                                                    <h4>{currentUser.name} </h4>
                                                 </div>
                                                 <div className="">
                                                    <span> Status :{currentUser.isVerified && currentUser.accountIsValid?<strong className='accountActif'>Actif</strong>:<strong className='accountNotAtif'>Non actif</strong>}</span>
                                                </div>
                                            </div>
                                         <hr class="navbar-divider"/>
                                        <NavLink  class="navbar-item" to="/account"><img id="profild" src={dashbord} alt="" />Tableau de bord</NavLink>
                                        <NavLink class="navbar-item" to="/profil"><img id="profild" src={user} alt="" />Mon profil</NavLink>
                                        <hr class="navbar-divider"/>
                                        <NavLink to="#" class="navbar-item" onClick={handleLogout}><img id="profild" src={logout} alt="" /> Se déconnecter</NavLink>
                                    </div>
                                </div>
                            </>
                        )}
                          
                        
                    </div>
                </div>
            </nav>**/
    
        )
    
}


export default Header;