import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Layouts/headerDashbord';
import Footer from '../../Layouts/Footer';
import Auth from '../../../contexts/Auth';
import { useForm } from "react-hook-form";
import {successnotif,errornotif} from  '../../../utils/notification';
import './Profil.css';
import Avatar  from '../../../img/avatar.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleNotch}  from '@fortawesome/free-solid-svg-icons';
import {getUser, updateUser} from '../../../services/user.service';
import {logOut, passwordResetService} from '../../../services/Auth';
import { useHistory } from 'react-router-dom';
import UserContext from '../../../contexts/UserContext';
import {infoNotif} from  '../../../utils/notification'
import SideMenu from './SideMenu'

const Profil = () =>{

  const {currentUser,setCurrentUser} = useContext(UserContext)

  const [formIsSuccessfullsubmit,setFormIsSuccessfullsubmit]= useState(false)
  const [resetPasswordRequestInit,setResetPasswordRequestInit]= useState(false)

  const history = useHistory()


  const { register,handleSubmit,trigger,reset,formState:{errors, isSubmitting ,isSubmitSuccessful,isValid},} = useForm({
    defaultValues: {
      name:"",
      phone:null,
      email:''
    }
 });
  const [loading,setLoading]= useState(isSubmitting)


  const onSubmit= async(data)=>{
  
    try {
      const response = await updateUser(data)
      successnotif(response.data.message)
      //window.location.reload();
    } catch ({response}) {
      //console.log(response.data.message)
      errornotif(response.data.message)
    }

  }
 
  const handleChangePassword = async(e,userEmail)=>{
        e.preventDefault();
        setResetPasswordRequestInit(true)
     try{       
        const response = await passwordResetService(userEmail)
        setResetPasswordRequestInit(false)
        infoNotif(response.data.message)
       }catch (error) {
        setResetPasswordRequestInit(false)
        setFormIsSuccessfullsubmit(false)
        errornotif(error.response.data.message)     
       }  
 
  }
  useEffect(() => {
    
    getUser().then(
      (response) => {
        
        setCurrentUser(response.data);
        reset(response.data)
      },
      (error) => {
        console.log("Private page", error.response);
        // Invalid token
        if (error.response && error.response.status === 403) {
          logOut();
          history.push("login")
          window.location.reload();
        }
      }
    );
  },[]);


  return (

    <section className="home_contents">
      <Header/>
      
      <div class="columns is-variable is-0 mt-4 ">  
        <SideMenu/>
        <div class="column is-10-desktop  is-12-tablet  is-12-mobile ">
            <div class="p-1" style={{margingBottom:"150px"}}>
                <div class="columns is-variable is-desktop   is-flex-direction-column	is-align-items-center	  has-shadow">
                    <div class="column  is-12 is-flex  is-flex-wrap-wrap  is-justify-content-space-around	"> 
                    <div class="card " style={{width:'100%',marginBottom:'200px'}}>
                        <header class="card-header">
                          <p class="card-header-title ">
                            Mon profil
                          </p>
                        </header>
                        <div class="card-content">
                          <div class="content">
                             <div className='column is-flex is-justify-content-center avatar'>
                                <img src={Avatar} height="80" width="80" alt=""/>
                              </div>
                              <div className='has-text-centered	'>
                                    <h3>{currentUser.name}</h3>
                                </div>

                            <br/>
                          </div>
                        </div>
                        <div class="card-content">
                        <form onSubmit={handleSubmit(onSubmit)}>
                                      <div className="form">
                                       <div className="input_field">
                                              <label>Email :<span className="isrequired">(*)</span></label>
                                              <div className="control">  
                                                  <input 
                                                      type='email'
                                                      className={`input  ${errors.email && 'error'}`}
                                                      {...register("email",{
                                                          required: 'L\'email est réquis',
                                                          pattern : {
                                                              value :/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                              message :'Votre email est invalid'
                                                          } 

                                                      })}
                                                      onKeyUp={()=>{
                                                          trigger("email");
                                                      }}
                                                  
                                                  />
                                                  {errors.email && <small className="error">{errors.email.message}</small> }

                                              </div>  
                                          </div>
                                        <div className="input_field">
                                              <label>Nom complet : <span className="isrequired">(*)</span></label>
                                              <div className="control">  
                                                  <input 
                                                      type='text' 
                                                    
                                                      className={`input  ${errors.name && 'error'}`}

                                                      {...register("name",{
                                                          required: 'Le nom est réquis', 
                                                      })}
                                                      onKeyUp={()=>{
                                                          trigger("name");
                                                      }}
                                                  
                                                  /> 
                                                  {errors.name && <small className="error">{errors.name.message}</small> }
                                              </div>
                                          </div>
                                          <div className="input_field">
                                              <label>Téléphone : <span className="isrequired">(*)</span></label>
                                              <div className="control">  
                                                  <input 
                                                      type='tel' 
                                                      className={`input  ${errors.phone && 'error'}`}
                                                      {...register("phone", {
                                                          required: 'Le numéro de téléphone est réquis',
                                                          pattern : {
                                                              value :/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
                                                              message :'Numéro de téléphone invalide'
                                                          }  
                                                      })}
                                                      onKeyUp={()=>{
                                                          trigger("phone");
                                                      }}
                                                  />
                                                  {errors.phone && <small className="error">{errors.phone.message}</small> }
                                              </div>
                                          </div>
                                          <div className="inputfield">
                                              <button type='submit' className={`button is-primary  is-small mt-4 is-responsive   next ${loading? 'is-loading' : null}` } >    Mettre à jour mon profil </button>
                                          </div>
                                      </div>
                         </form>
                        </div>

                        <div class="card-footer p-3 ">
                         <button className={`button  is-info is-responsive is-small  ${resetPasswordRequestInit? 'is-loading' : null}`  } onClick={(e)=>{handleChangePassword(e,currentUser.email)}}>Modifier mon mot de passe </button>
            
                        </div>
                      </div>
                    {/**   
                    <div className="container-fluid profil-wrapper is-flex is-justify-content-space-around is-flex-direction-row">
                          <div className='profil' >
                              <div className='avatar'>
                                  <div className='image'>
                                    <img src={Avatar} alt=""/>
                                  </div>
                                  <h3>{currentUser.name}</h3>
                              </div>
                      
                          </div>
                          <div className="profil-form">  
                              <div className="title">
                                      <h3>Mon Profil</h3>
                              </div>                                              
                                  <form onSubmit={handleSubmit(onSubmit)}>
                                      <div className="form">
                                      <div className="input_field">
                                              <label>Email :<span className="isrequired">(*)</span></label>
                                              <div className="control">  
                                                  <input 
                                                      type='email'
                                                      className={`input  ${errors.email && 'error'}`}
                                                      {...register("email",{
                                                          required: 'L\'email est réquis',
                                                          pattern : {
                                                              value :/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                              message :'Votre email est invalid'
                                                          } 

                                                      })}
                                                      onKeyUp={()=>{
                                                          trigger("email");
                                                      }}
                                                  
                                                  />
                                                  {errors.email && <small className="error">{errors.email.message}</small> }

                                              </div>  
                                          </div>
                                      <div className="input_field">
                                              <label>Nom complet : <span className="isrequired">(*)</span></label>
                                              <div className="control">  
                                                  <input 
                                                      type='text' 
                                                    
                                                      className={`input  ${errors.name && 'error'}`}

                                                      {...register("name",{
                                                          required: 'Le nom est réquis', 
                                                      })}
                                                      onKeyUp={()=>{
                                                          trigger("name");
                                                      }}
                                                  
                                                  /> 
                                                  {errors.name && <small className="error">{errors.name.message}</small> }
                                              </div>
                                          </div>
                                        
                                          <div className="input_field">
                                              <label>Téléphone : <span className="isrequired">(*)</span></label>
                                              <div className="control">  
                                                  <input 
                                                      type='tel' 
                                                      className={`input  ${errors.phone && 'error'}`}
                                                      {...register("phone", {
                                                          required: 'Le numéro de téléphone est réquis',
                                                          pattern : {
                                                              value :/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
                                                              message :'Numéro de téléphone invalide'
                                                          }  
                                                      })}
                                                      onKeyUp={()=>{
                                                          trigger("phone");
                                                      }}
                                                  />
                                                  {errors.phone && <small className="error">{errors.phone.message}</small> }
                                              </div>
                                          </div>
                                          
                                      
                                      
                                          <div className="inputfield">
                                              <button type='submit' className={`btnp  ${!isValid? 'griser' : null}`} >   {loading?<FontAwesomeIcon icon={faCircleNotch} className="fa-spin"/>  : null   } Mettre à jour </button>
                                          </div>
                                      </div>
                                  </form>
                                  <div className="footer">
                                  <p>  <button className='btn btn-green' onClick={(e)=>{handleChangePassword(e,currentUser.email)}}>Modifier mon mot de passe </button></p>
                              </div>
                          </div>
  
                    </div>*/} 
                    </div>
                </div>

            </div>
        </div>
        </div>
           
         
       
      <Footer />
    </section>
  )
}

export default Profil;