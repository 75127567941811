import React, { useContext, useEffect, useState } from "react";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import {useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleNotch}  from '@fortawesome/free-solid-svg-icons';
import Auth from "../../../contexts/Auth";
import {login} from '../../../services/Auth';
import {successnotif,errornotif} from  '../../../utils/notification';
import { NavLink } from "react-router-dom";

const Login=()=>{
    const {isAuthenticated,setIsAuthenticated} = useContext(Auth)
    const { register,handleSubmit,trigger,reset,watch,formState: {errors, isSubmitting,isSubmitSuccessful,isValid},} = useForm();
    const [loading,setLoading]= useState(isSubmitting)
    const history = useHistory()
    const onSubmit = async(data)=>{
        setLoading(true)
        try {
            const response = await login(data.email,data.password)
             setIsAuthenticated(response)
             setLoading(false)
             history.push('/account')  
        } catch ({ response }) {
            setLoading(false)

            errornotif(response.data.message)
        }
           
    }

    useEffect(()=>{
        if(isAuthenticated){
            history.push('/account')
        }
    },[history,isAuthenticated])


    return(
        <section className="home_contents" >
         <Header/>
         <div class="container">
          <div class="colomuns is-flex is-justify-content-center  is-align-content-center mt-6" style={{ marginBottom: "2rem" }}>
            
            <div class="column   is-12-mobile is-12-tablet is-5-desktop card">
                
                        <h3 className="has-text-dark	 is-size-4 is-uppercase	has-text-centered has-text-weight-bold 	">Connexion</h3>
               
                    <div className="card-content">
                      <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form">
                            
                                <div className="input_field">
                                    <label>Email :<span className="isrequired">(*)</span></label>
                                    <div className="control">  
                                    <input type='email' class="input"
                                     
                                    className={`input  ${errors.email && 'error'}`}
                                    {...register("email", {
                                        required: 'L\'email est réquis',
                                        pattern : {
                                            value :/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                            message :'Votre email est invalid'
                                        } 

                                    })}
                                    onKeyUp={()=>{
                                        trigger("email");
                                    }}
                                    />
                                   
                                  {errors.email && <small className="error">{errors.email.message}</small> }
                                </div>
                                </div>
                            
                                <div className="input_field">
                                    <label>Mot de passe : <span className="isrequired">(*)</span></label>
                                    <div className="control">  
                                    <input type='password' class="input"
                                        className={`input  ${errors.password && 'error'}`}
                                        {...register("password", {
                                            required: 'Le mot de passe est réquis',
                                            pattern : {
                                                value :/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                                                message :"- Au moins 8 caractères ; doit contenir au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre ;- Peut contenir des caractères spéciaux"
                                            } 
                                        })}
                                        onKeyUp={()=>{
                                            trigger("phone");
                                        }}
                                    />
                                    
                                 {errors.password && <small className="error">{errors.password.message}</small> }

                                  
                                      <p className="password-reset has-text-right"><NavLink to="/password-reset-request" className="link"> Mot de passe oublié</NavLink></p>

                                 </div>
                                 
                                </div>
                            
                                <div className="inputfield mt-4">

                                    <button type='submit' className={`button  is-fullwidth is-success  ${!isValid? 'griser' : null} ${loading? 'is-loading' : null}`} >Se Connecter </button>
                                </div>
                            </div>
                      </form>
                    </div>
                

                     
                     <div className="card-footer ">
                        <p> Nouveau ?<NavLink to="/register" className="link"> Je crée mon compte</NavLink></p>
                    </div>
              
            </div>
          </div>
        </div>
        <Footer/>

      </section>       

    );
}
export default Login;