import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../Layouts/headerDashbord';
import Footer from '../../../Layouts/Footer';
import Auth from '../../../../contexts/Auth';
import { useForm } from "react-hook-form";
import {successnotif,errornotif} from  '../../../../utils/notification'
import UserContext from '../../../../contexts/UserContext';
import {getUser} from '../../../../services/user.service';
import {logOut} from '../../../../services/Auth';
import { useHistory } from 'react-router-dom';
import StepForm from './StepForm';
import SideMenu from '../SideMenu';

const AddSolution = () =>{

  const {currentUser,setCurrentUser} = useContext(UserContext)


  const history = useHistory()
  const { register,handleSubmit,trigger,reset,watch,formState: {errors,isDirty, isSubmitting ,isSubmitSuccessful},} = useForm();

  const [loading,setLoading]= useState(isSubmitting)
  
  const onSubmit= (data)=>{
    console.log(data)
  }
 
  useEffect(() => {
    getUser().then(
      (response) => {
        setCurrentUser(response.data);
      },
      (error) => {
        console.log("Private page", error.response);
        // Invalid token
        if (error.response && error.response.status === 403) {
          logOut();
          history.push("login")
          window.location.reload();
        }
      }
    );
  },[]);

  return (

    <section className="home_contents">
      <Header/>
        <div class="columns is-variable is-0 mt-4 "> 
            
            <SideMenu currentPage='my-applications'/>

        <div class="column is-10-desktop  is-12-tablet  is-12-mobile ">
            <div class="p-1">
                <div class="columns is-variable is-desktop   is-flex-direction-column	is-align-items-center	  has-shadow">
                    <div class="column is-12 is-flex  is-flex-wrap-wrap  is-justify-content-space-around	">
                             <StepForm/>
                     

                    </div>
                
                
                </div>

            
        
            </div>
        </div>
        </div>
        

       
            


            {/** <div className="container-fluid ce ">
                        <div className="wrapper">
                            <div className="title">
                                    <h3>Ajouter une solution</h3>
                            </div>
                         
                         
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form">
                                        <div className="input_field">
                                            <label>Nom de l'entreprise: <span className="isrequired">(*)</span></label>
                                            <div className="control">  
                                                <input 
                                                    type='text' 
                                                    className={`input  ${errors.name && 'error'}`}
                                                    {...register("entreprise",{
                                                        required: 'Le Nom de l\'entreprise est réquis', 
                                                        
                                                    })}
                                                    onKeyUp={()=>{
                                                        trigger("entreprise");
                                                    }}
                                                
                                                /> 
                                                {errors.entreprise && <small className="error">{errors.entreprise.message}</small> }
                                            </div>
                                        </div>
                                        
                                        <div className="input_field">
                                            <label>Lien de la boutique: <span className="isrequired">(*)</span></label>
                                            <div className="control">  
                                                <input 
                                                    type='text' 
                                                    className={`input  ${errors.name && 'error'}`}
                                                    {...register("shopLink",{
                                                        required: 'Le lien de la boutique  est réquis', 
                                                        pattern : {
                                                          value :/((http|https)\:\/\/||www)[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/,
                                                          message :'Le  lien est invalid'
                                                      } 
                                                    })}
                                                    onKeyUp={()=>{
                                                        trigger("shopLink");
                                                    }}
                                                
                                                /> 
                                                {errors.shopLink && <small className="error">{errors.shopLink.message}</small> }
                                            </div>
                                        </div>

                                        <div className="input_field">
                                            <label>Plateforme (CMS): <span className="isrequired">(*)</span></label>
                                            <div className="control">  
                                                <select 
                                                    type='text' 
                                                    className={`input  ${errors.name && 'error'}`}
                                                    {...register("platform",{
                                                        required: 'Ce champ  est réquis', 
                                                        
                                                    })}
                                                    onKeyUp={()=>{
                                                        trigger("platform");
                                                    }}
                                                >
                                                   <option value="">Sélectionner...</option>
                                                  <option value="Prestashop">Prestashop</option>
                                                  <option value="Wordpress (woocommerce)">Wordpress (woocommerce)</option>
                                                  <option value="Shopify">Shopify</option>
                                                    
                                              </select>
                                                {errors.platform && <small className="error">{errors.platform.message}</small> }
                                            </div>
                                        </div>
                                        <div className="input_field">
                                            <label>Numéro de réception Mobile money: <span className="isrequired">(*)</span></label>
                                            <div className="control">  
                                                <input 
                                                    type='tel' 
                                                    className={`input  ${errors.phone && 'error'}`}
                                                    {...register("phone", {
                                                        required: 'Le numéro de réception est réquis',
                                                        pattern : {
                                                            value :/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
                                                            message :'Numéro de  réception invalid'
                                                        }  
                                                    })}
                                                    onKeyUp={()=>{
                                                        trigger("phone");
                                                    }}
                                                />
                                                {errors.phone && <small className="error">{errors.phone.message}</small> }
                                            </div>
                                        </div>
                                      
                                    
                                        <div className="inputfield">
                                            <button type='submit' className={`btnp  ${!isValid? 'griser' : null}`} >   {loading?<FontAwesomeIcon icon={faCircleNotch} className="fa-spin"/>  : null   } Ajouter </button>
                                        </div>
                                    </div>
                                </form>
                            <div className="footer">
                            </div>
                        </div>
 
             </div>
                                                **/}
         
       
      <Footer />
    </section>
  )
}

export default AddSolution;