import axios from "axios";
import jwtDecode from "jwt-decode";
import { getItem,addItem,removeItem } from "./LocalStorage";



export const registerServices =(data)=>{
    const userData = {
        name: data.name,
        email:data.email,
        phone: data.phone,
        password: data.password,
        termsAccepted : data.isChecked
    }

    const url=process.env.REACT_APP_SERVER_URL+"/api/user/registration";
    return axios.post(url,userData)
}

export function hasAuthenticated() {
    const token = getItem('accessToken');
    const result = token ? tokenIsValid(token) : false;
    if (false === result) {
        removeItem('accessToken');
    }
    return result;
}

export  const login = async(email,password)=>{
    const userData = {
        email:email,
        password:password
    }

    return axios
    .post(process.env.REACT_APP_SERVER_URL+'/api/user/login',userData)
    .then(response => {
        if(response.data.accessToken){
            localStorage.setItem("accessToken",JSON.stringify(response.data));
        }
        return response.status
    })
}
export const passwordResetService=(email)=>{
    const data={
        email:email,
        redirectUrl:process.env.REACT_APP_CLIENT_URL+"/password-reset"
    }

    const url=process.env.REACT_APP_SERVER_URL+"/api/user/password-reset-request";
    console.log(data)
   
    return axios.post(url,data)
}
export const logOut= ()=>{
    localStorage.removeItem ('accessToken')  
}

function tokenIsValid(token) {
    const { exp: expiresIn } = jwtDecode(token);

    if (expiresIn * 1000 > new Date().getTime()) {
        return true;
    }

    return false;
}