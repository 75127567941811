import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck}  from '@fortawesome/free-solid-svg-icons';
import {faCircleCheck}  from '@fortawesome/free-solid-svg-icons';


const  Checkresetmail = ()=> {
  return (
    <section className="home_contents">
        <div class="container">
                <div class="colomuns is-flex is-justify-content-center  is-align-content-center mt-6" style={{ marginBottom: "2rem" }}>
                    <div class="column   is-11-mobile is-6-tablet is-6-desktop card">
                            <h3 className="has-text-dark	 is-size-4 is-uppercase	has-text-centered has-text-weight-bold 	"> Réinitialisation de mot de passe </h3>               
                            <div className="card-content">
                            <div className="icon-container">
                                <span class="icon-text has-text-primary">
                                  <span class="icon ">
                                     <FontAwesomeIcon icon={faCircleCheck}  />
                                    </span>
                                  <span>Success</span>
                                </span> 
                                <p className="description">Un e-mail vient de vous être envoyé.<br/> contenant le lien de réinitialisation de votre mot de passe ce lien expira dans 6 heures <br/> </p>
                                <p className="description">Si vous ne recevez pas d'email , veuillez vérifier votre spam ou <a href="/password-reset-request"> réessayer</a></p>
                            </div>
                            </div>

                    </div>
                </div>
                </div>
   
    </section>
  )
}
export default Checkresetmail;
