import React from "react";
import { useRef,useState } from "react";
import axios from "axios";
import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import { useForm } from "react-hook-form";
import {successnotif,errornotif} from  '../../../utils/notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleNotch}  from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';


    

const PasswordReset= ()=>{

    const { register,handleSubmit,trigger,reset,watch,formState: {errors, isSubmitting ,isValid},} = useForm();
   
    const [loading,setLoading]= useState(isSubmitting)
 

    const {token}= useParams()

    const password = useRef({});
    password.current = watch("password", "");
    const history = useHistory()

    const onSubmit = async(data)=>{  
       setLoading(true)
        const userData = {
            token:token,
            newPassword: data.password,
            
        }
         try{       
            const url=process.env.REACT_APP_SERVER_URL+"/api/user/password-reset";
            const response = await axios.post(url,userData)
            reset()
            //if(response.status === 201) setFlashSuccess(response.data.message)
            setLoading(false)
          //  setFlashSuccess(response.data.message)
            successnotif(response.data.message)
                if(response.status===200){
                    history.push('/login');
                }
           
           }catch (error) {
            setLoading(false)
          // setFlashError(error.response.data.message)
             console.log(error)
             errornotif(error.response.data.message)
        }  
          

    }


    return(
   
        <section className="home_contents">
        <Header/>
                <div class="container">
                <div class="colomuns is-flex is-justify-content-center  is-align-content-center mt-6" style={{ marginBottom: "2rem" }}>
                    
                    <div class="column   is-12-mobile is-12-tablet is-5-desktop card">
                        
                                <h3 className="has-text-dark	 is-size-4 is-uppercase	has-text-centered has-text-weight-bold 	"> Définir un nouveau mot de passe</h3>
                    
                            <div className="card-content">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form">
                                            
                                                <div className="input_field">
                                                    <label>Nouveau mot de passe : <span className="isrequired">(*)</span></label>
                                                    <div className="control">  
                                                        <input 
                                                            type='password'
                                                            className={`input  ${errors.password && 'error'}`}
                                                            {...register("password", {
                                                                required: 'Le mot de passe est réquis',
                                                                pattern : {
                                                                    value :/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                                                                    message :"- Au moins 8 caractères ; doit contenir au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre ;- Peut contenir des caractères spéciaux"
                                                                } 
                                                            })}
                                                            onKeyUp={()=>{
                                                                trigger("phone");
                                                            }}
                                                            />
                                                            {errors.password && <small className="error">{errors.password.message}</small> }
                                                    </div>
                                                </div>
                                                <div className="input_field">
                                                    
                                                    <label>Confirmer le mot de passe : <span className="isrequired">(*)</span></label>
                                                    <div className="control">  
                                                        <input 
                                                            type='password'
                                                            className={`input  ${errors.confPassword && 'error'}`} 
                                                            {...register("confPassword",{
                                                                required:'Le champs est réquis',
                                                                validate: value =>
                                                                value === password.current || "Le mot de passe ne correspond pas"
                                                        
                                                            })}
                                                            
                                                        />
                                                        {errors.confPassword && <small className="error">{errors.confPassword.message}</small> }
                                                    </div>
                                                </div>
                                        
                                                <div className="inputfield">
                                                    <button type='submit' className={`button  is-fullwidth is-success  ${!isValid? 'griser' : null} ${loading? 'is-loading' : null}`}>   Réinitialiser </button>
                                                </div>
                                            </div>
                                        </form>
                            </div>

                    </div>
                </div>
                </div>
          
          <Footer/>
      </section>
    
    );
}

export default PasswordReset;