
import React, { Component, useContext, useState } from "react";
import Home from './components/Pages/Home/Home';
import LegalMention from './components/Pages/Mention/LegalMention';
import FaqPage from './components/Pages/Faq/FAQ';
import {BrowserRouter,Switch,Route,Redirect}from "react-router-dom";
import Login from "./components/Pages/Login/Login";
import Register from "./components/Pages/Register/Register";
import registerSuccess from "./components/Pages/Register/registrationSuccess";
import PasswordResetRequest from "./components/Pages/Login/Password-reset-request";
import PasswordReset from "./components/Pages/Login/Password-reset";
import Dashbord from "./components/Pages/Account/Dashbord";
import AddApplication from "./components/Pages/Account/Application/Add-solution";
import MyApplications from "./components/Pages/Account/Application/All-application";
import TransactionHistory from "./components/Pages/Account/Transaction-history"
import TransactionHistoryApp from "./components/Pages/Account/TransactionHistoryApp"
import Profil from "./components/Pages/Account/Profil"
import EditAppConfig from './components/Pages/Account/Application/EditAppConfig'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ActivationEmail from "./components/Pages/Register/ActivationEmail";
import {hasAuthenticated} from "./services/Auth";
import AuthenticateRoute from "./components/Pages/Login/AuthenticateRoute";
import Auth from "./contexts/Auth";
import UserContext from "./contexts/UserContext";
import "./css/mystyles.css"
import "../src/theme/index.css"
import SideMenuIsvisibleContext from './contexts/sideMenuIsvisibleContext'
const App = ()=> {
 
  const [isAuthenticated,setIsAuthenticated] = useState(hasAuthenticated())
  const [currentUser,setCurrentUser]=useState({
  })
  const [sideMenuIsvisible,setSideMenuIsvisible] = useState(false);
  const sideMenuIsvisibleDetails = {
    sideMenuIsvisible:sideMenuIsvisible,
    setSideMenuIsvisible:setSideMenuIsvisible
  };
  
  return (
      <Auth.Provider value={{isAuthenticated,setIsAuthenticated }}>
        <UserContext.Provider value={{currentUser,setCurrentUser}}>



        
          <BrowserRouter>
            <Switch >
          
              <Route exact path="/accueil" render={ () => (
              <Redirect exact to={"/"} />
              )} />
              <Route exact path={"/"} component={Home} />
              <Route path={"/mentions_legales"} component={LegalMention} />  
              <Route path={"/faq"} component={FaqPage} />  
              
              <Route path={"/login"} component={Login} /> 
              <Route path={"/register"} component={Register} /> 

              <Route path={"/password-reset-request"} component={PasswordResetRequest} /> 
              <Route path={"/password-reset/:token"} component={PasswordReset} /> 

              <Route path={"/user/activate/:token"} component={ActivationEmail} /> 

              <SideMenuIsvisibleContext.Provider value={{sideMenuIsvisibleDetails}}>
                  <AuthenticateRoute path="/account" component={Dashbord}/>
                  <AuthenticateRoute path="/add-application" component={AddApplication}/>
                  <AuthenticateRoute path="/my-applications" component={MyApplications}/>

                  <AuthenticateRoute path="/editAppConfig/:id" component={EditAppConfig}/>

                  <AuthenticateRoute path="/transaction-history" component={TransactionHistory}/>
                  <AuthenticateRoute path="/transaction-history/:id" component={TransactionHistoryApp}/>

                  <AuthenticateRoute path="/profil" component={Profil}/>
                  
               </SideMenuIsvisibleContext.Provider>

          
          
            </Switch>      
          </BrowserRouter>
          <ToastContainer/>
          </UserContext.Provider>
      </Auth.Provider>
    )
}

export default App;