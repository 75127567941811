import React, { Component  } from "react";
import './Footer.css';
//import '../../assets/js/index';

class Footer extends Component {
    render(){
        return (
            <div className="footer-space">
            <footer class="footer has-background-black mt-6	">
                <div class="content has-text-centered has-text-white	">
                <p>  @ 2021 - Propulsé par <a href="http://rintio.com" target="_blank" rel="noopener noreferrer">RINTIO</a></p>
                </div>
            </footer>
            </div>
        );
    }
}
export default Footer ;