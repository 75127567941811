import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Layouts/headerDashbord';
import Footer from '../../Layouts/Footer';
import Auth from '../../../contexts/Auth';
import {getUser} from '../../../services/user.service';
import {logOut} from '../../../services/Auth';
import UserContext from '../../../contexts/UserContext';
import { useHistory } from 'react-router-dom';
import SideMenuIsvisibleContext from '../../../contexts/sideMenuIsvisibleContext'
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDashboard,faList,faHistory,faChartLine,faComments,faBook}  from '@fortawesome/free-solid-svg-icons';


const SideMenu = (props) =>{
  const {currentUser,setCurrentUser} = useContext(UserContext)

    const sideMenuIsvisibleContext = useContext(SideMenuIsvisibleContext);
    const updateSideMenuIsvisible = sideMenuIsvisibleContext.sideMenuIsvisibleDetails;
    const sideMenuIsvisible = updateSideMenuIsvisible.sideMenuIsvisible



  return (
 
     
            <> 
       
              <div class={`column is-2-desktop  menu-container px-1 has-background-whit ${sideMenuIsvisible?'is-visible ':''}`} >
                    <div class="menu-wrapper py-1 ox">
                    <aside class="menu  ">
                    <p class="menu-label">
                          COMPTE
                      </p>
                      <ul class="">
                        <li><span className='has-text-weight-bold	'>Statut : </span>{currentUser.isVerified && currentUser.accountIsValid?<strong className='has-text-primary'>Actif</strong>:<strong className='has-text-danger'>Non actif</strong>}</li>
                    
                      </ul>
                        <p class="menu-label has-text-lighter">
                          Emobpay
                        </p>
                        <ul class="menu-list">
                          <li ><NavLink  to="/account"  class={ `has-text-weight-bold	 ${props.currentPage==='dashboard'? 'is-active ' :''}`}><FontAwesomeIcon className="" icon={ faDashboard} /> &ensp;Dashboard</NavLink></li>
                        </ul>
                        <p class="menu-label">
                         Applications
                        </p>
                        <ul class="menu-list">
                          <li ><NavLink to="/my-applications" class={ `has-text-weight-bold	 ${props.currentPage==='my-applications'? 'is-active ' :''}`}> <FontAwesomeIcon className="" icon={ faList} /> &ensp; Mes applications</NavLink></li>
                        </ul>
                        <p class="menu-label">
                          Transactions
                        </p>
                        <ul class="menu-list">
                          <li><NavLink to="/transaction-history" class={ `has-text-weight-bold	 ${props.currentPage==='transaction-history'? 'is-active ' :''}`}>  <FontAwesomeIcon className="" icon={ faHistory} /> &ensp;Historique de transaction</NavLink></li>

                        </ul>
                      
                    </aside>
                  </div>
              </div>
         
            </>

 
  )
}

export default SideMenu;