import axios from "axios";
import authHeader from "./auth-header";

export  const addAppService = (data) => {
    const appData = {
        appName: data.appName,
        appUrl:data.appUrl,
        platform: data.platform,
        transactionNumber: data.transactionNumber,
    }
    return axios.post(process.env.REACT_APP_SERVER_URL+"/api/application/addApp",appData, { headers: authHeader() });
 };
 export const getAllAppServices = () => {
    return axios.get(process.env.REACT_APP_SERVER_URL+"/api/application/getAllApp", { headers: authHeader() });
  };
  export const getAppByIdServices = (id) => {
    return axios.get(process.env.REACT_APP_SERVER_URL+`/api/application/getAppById/${id}`, { headers: authHeader() });
  };
  export const updateAppServices = (id,data) => {
    const appData ={
      appName:data.appName,
      appUrl:data.appUrl,
      platform:data.platform,
      transactionNumber:data.transactionNumber
    }
    return axios.put(process.env.REACT_APP_SERVER_URL+`/api/application/updateApp/${id}`, appData,{ headers: authHeader() });
  };
  export const deleteAppServices = (id) => {
    return axios.delete(process.env.REACT_APP_SERVER_URL+`/api/application/deleteApp/${id}`, { headers: authHeader() });
  };