import React, {useContext} from 'react';
import AddApplicationContext from '../../../../contexts/addApplicationContext.js';

const ProgressBar = () => {
    const addApplicationContext = useContext(AddApplicationContext);
   
    const addApplicationDetails = addApplicationContext.addApplicationDetails;
    
    const percent = addApplicationDetails.step * 50;

    const percentage = addApplicationDetails.step;


    const text = {
        fontSize: 12,
        color: '#8d99ae',
    }

    return (
                    <ul class="steps is-steps is-horizontal ">
                        <li class={`steps-segment ${addApplicationDetails.step<=0? 'is-active' :''}`}>
                        <span class="steps-marker  ">1</span>
                        </li>
                        <li class={ ` steps-segment ${addApplicationDetails.step<=1? 'is-active' :''} ` }>
                        <span class="steps-marker  ">2</span>
                        </li>
                        <li class={ ` steps-segment ${addApplicationDetails.step<=2? 'is-active' :''} ` }>
                        <span class="steps-marker   ">3</span>
                        </li>
                        <li class={ ` steps-segment   ${addApplicationDetails.step<=3? 'is-active' :''} ` }>
                        <span class="steps-marker">FIN</span>
                        </li>
   
                    </ul>
                 /** *  <div class="wizard">
                        <div class="wizard-inner">
                            <div class="connecting-line"></div>
                            <ul class="nav nav-tabs" role="tablist">
                                <li role="presentation" class={addApplicationDetails.step>=0? 'active' :'disabled'  }>
                                    <a href="#step1" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span class="round-tab">1 </span> <i>Étape 1</i></a>
                                </li>
                                <li role="presentation" class={addApplicationDetails.step>=1? 'active' :'disabled'  }>
                                    <a href="#step2" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="false"><span class="round-tab">2</span> <i>Étape 2</i></a>
                                </li>
                                <li role="presentation" class={addApplicationDetails.step>=2? 'active' :'disabled'  }>
                                    <a href="#step3" data-toggle="tab" aria-controls="step3" role="tab"><span class="round-tab">3</span> <i>Étape 3</i></a>
                                </li>
                                <li role="presentation" class={addApplicationDetails.step>=3? 'active' :'disabled'  }>
                                    <a href="#step4" data-toggle="tab" aria-controls="step4" role="tab"><span class="round-tab">4</span> <i>Étape 4</i></a>
                                </li>
                            </ul>
                        </div>
                   </div>**/
        

      /**  <div>
                        <div class="progress progesseBar">
                            <div class="progress-bar  progress-bar-success progress-bar-striped"  role="progressbar" aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100" style={{width:`${percent}%`}}>
                            </div>
                            {percent}%
                        </div>
                        <smal style={text}>{addApplicationDetails.step } sur 3 terminé</smal>


           
       
        </div>*/
     
    );
};

export default ProgressBar;